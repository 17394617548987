import {
  TimeDistance,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { fetchRemoteAccessToken } from '../../actions/balena';
import {
  BalenaDeviceStatus,
  BalenaFinalStatus,
  BalenaStatus,
} from '../../reducers/balena';
import { fetchApi } from '../../utils/apiUtils';
import { RemoteAccess } from '@energybox/react-ui-library/dist/icons';
import { classNames, global } from '@energybox/react-ui-library/dist/utils';
import styles from './RemoteAccessButton.module.css';

type Props = {
  serialNumber: string | false;
  balenaStatus:
    | BalenaStatus
    | BalenaDeviceStatus
    | {
        online: boolean;
        lastConnectivityEvent: string;
        finalStatus: BalenaFinalStatus;
      }
    | undefined;
  path?: string;
  className?: string;
};

const colorMapping: Record<BalenaFinalStatus, string> = {
  Online: 'green',
  Offline: 'red',
  Frozen: 'red',
  Inactive: 'gray',
  'Online (VPN Only)': 'orange',
  'Online (Heartbeat Only)': 'orange',
  Configuring: 'outline',
  Updating: 'outline',
  'Post Provisioning': 'gray',
};

const RemoteAccessButton: React.FC<Props> = ({
  serialNumber,
  balenaStatus,
  path,
  className,
}) => {
  if (!serialNumber) {
    return (
      <div className={className}>
        <div className={styles.remoteAccessButton}>
          <RemoteAccess size={25} tint="gray" />
        </div>
      </div>
    );
  }

  const startRemoteAccessInNewTab = async (serialNumber: string) => {
    const { accessToken } = await fetchApi(
      fetchRemoteAccessToken(serialNumber)
    );

    const remoteAccessUrl =
      getRemoteAccessUrl(serialNumber) + `?token=${accessToken}`;
    const a = document.createElement('a');
    a.href = remoteAccessUrl;

    a.setAttribute('target', '_blank');
    a.click();
    a.remove();
  };

  const getRemoteAccessUrl = (serialNumber: string) =>
    `https://${serialNumber}.${process.env.REACT_APP_SUPERHUB_REMOTE_DOMAIN}${
      path ? path : ''
    }`;

  const color = colorMapping[balenaStatus?.finalStatus || 'Offline'];

  return (
    <div className={className}>
      <div
        className={styles.remoteAccessButton}
        onClick={() => startRemoteAccessInNewTab(serialNumber)}
      >
        <Tooltip
          underline={false}
          arrowDirection="topRight"
          content={
            <div className={styles.tooltipContent}>
              <i>Status</i>
              <div className={styles[color]}>
                {balenaStatus?.finalStatus || 'Offline'}
              </div>
              <TimeDistance
                timestamp={balenaStatus?.lastConnectivityEvent}
                prefix={'For'}
                suffix={false}
              />

              <div className={styles.tooltipSeparator} />

              <i>Public URL to enter local admin portal</i>
              <div className={styles.remoteAccessUrl}>
                {getRemoteAccessUrl(serialNumber)}
              </div>
            </div>
          }
        >
          <RemoteAccess size={25} />
          <div
            className={classNames(
              color === 'outline'
                ? styles.statusDotOutterOutline
                : styles.statusDotOutter,
              styles[color]
            )}
          >
            <div className={styles.statusDotInner} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default RemoteAccessButton;
