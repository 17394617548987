import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  DeviceType,
  GenericErrors,
  NetworkGroup,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as NetworkGroupActions,
  create,
  destroy,
  displayFormErrors,
  hideNewNetworkGroupModal,
  reset,
  updateField,
  clearFormErrors,
} from '../../actions/network_groups';
import EditNetworkGroupForm from '../../components/EditNetworkGroupForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/network_groups';
import { CreateNewText } from '../../types/global';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import styles from './NewNetworkGroupModal.module.css';
import { renderNewIotodFields } from '../../actions/iotod';
import {
  Actions as GatewayActions,
  showNewGatewayModalForSuperHub,
  create as createHub,
  updateField as updateHub,
  clearFormErrors as clearHubFormErrors,
} from '../../actions/gateways';
import { EditGateway } from '../../reducers/gateways';

interface Props {
  isVisible: boolean;
  lastCreatedId: string;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onCreate: typeof create;
  onDelete: typeof destroy;
  onReset: typeof reset;
  formErrors: GenericErrors;
  fields: EditableFields;
  isLoading: boolean;
  apiError: { type?: string; status?: number; message?: string };
  displayFormErrors: () => void;
  formErrorsVisible: boolean;
  lockSiteId?: number;
  showNewGatewayModal: typeof showNewGatewayModalForSuperHub;
  createHub: (siteId: number) => void;
  updateHub: (field: string, value: any) => void;
  gateway: EditGateway;
  lookupMACAddressError: boolean;
}

interface State {
  siteId: number;
}

class NewNetworkGroupModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      siteId: props.lockSiteId || -1,
    };
  }

  componentDidUpdate() {
    const { lastCreatedId, apiError, onDelete } = this.props;
    if (hasKeys(apiError) && !!lastCreatedId) {
      displayFormErrors();
      onDelete(lastCreatedId);
    }
  }

  componentWillUnmount(): void {
    const { lastCreatedId, onReset } = this.props;
    if (lastCreatedId) {
      onReset(lastCreatedId);
    }
  }

  onNetworkGroupCreate = () => {
    const {
      formErrors,
      displayFormErrors,
      onCreate,
      lockSiteId,
      fields,
      updateHub,
      createHub,
      gateway,
    } = this.props;

    if (hasKeys(formErrors)) {
      displayFormErrors();
    } else {
      const siteId = lockSiteId ? lockSiteId : fields.siteId;
      onCreate(siteId, (networkGroup: NetworkGroup) => {
        if (networkGroup.edge?.edgeDevice === 'EB_SUPER_HUB') {
          updateHub('title', `${fields.title}-Hub`);
          updateHub('description', '');
          updateHub('uuid', gateway?.fields?.uuid);
          updateHub('model', DeviceType.EB_SUPER_HUB);
          updateHub('spaceId', fields.spaceId);
          updateHub('networkGroupId', networkGroup.id);
          createHub(siteId);
        }
      });
    }
  };

  render() {
    if (!this.props.isVisible) return null;

    const {
      onChange,
      onClose,
      isLoading,
      formErrors,
      fields,
      apiError,
      formErrorsVisible,
      lockSiteId,
      lookupMACAddressError,
    } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={isLoading || lookupMACAddressError}
          onClick={this.onNetworkGroupCreate}
        >
          Add
        </Button>
      </>
    );

    return (
      <Modal
        containerClassName={styles.modalContainer}
        onClose={onClose}
        actions={actions}
        disableEscapeClose
      >
        <ModalTitle>{CreateNewText.NETWORK_GROUP}</ModalTitle>
        <ModalContent>
          <EditNetworkGroupForm
            {...fields}
            lockSiteId={lockSiteId}
            onChange={onChange}
            updateHub={updateHub}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
          />
          {renderAPIerror(
            apiError,
            NetworkGroupActions.CREATE_NETWORK_GROUP_ERROR
          )}
          {apiError?.type === GatewayActions.CREATE_GATEWAY_ERROR ? (
            <>
              <div
                style={{
                  color: 'var(--pink-base)',
                  textAlign: 'center',
                  fontSize: '0.75rem',
                }}
              >
                {`create gateway error:`}
              </div>
              {renderAPIerror(apiError, GatewayActions.CREATE_GATEWAY_ERROR)}
            </>
          ) : null}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  networkGroups,
  gateways,
  balena,
}: ApplicationState) => ({
  isVisible: networkGroups.showNewNetworkGroupModal,
  ...networkGroups.editById.new,
  lastCreatedId: networkGroups.lastCreatedId,
  gateway: gateways.editById.new,
  lookupMACAddressError: balena.lookupMACAddressError,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClose: () => {
      dispatch(hideNewNetworkGroupModal());
      dispatch(renderNewIotodFields());
    },
    onChange: (field: string, value: string) => {
      dispatch(clearFormErrors());
      dispatch(clearHubFormErrors());
      dispatch(updateField('new', field, value));
    },
    onCreate: (
      siteId: number,
      callback?: (networkGroup: NetworkGroup) => void
    ) => {
      dispatch(create(siteId, callback));
    },
    onDelete: networkGroupId => dispatch(destroy(networkGroupId)),
    onReset: networkGroupId => dispatch(reset(networkGroupId)),
    createHub: lockSiteId => dispatch(createHub(lockSiteId)),
    updateHub: (field, value) => dispatch(updateHub('new', field, value)),
    showNewGatewayModal: (siteId: string) =>
      dispatch(showNewGatewayModalForSuperHub(siteId)),
    displayFormErrors: () => dispatch(displayFormErrors()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewNetworkGroupModal);
