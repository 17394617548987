import { Button, Table } from '@energybox/react-ui-library/dist/components';
import { SensorsWhitelist } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { ScannedSensor } from '../../reducers/scannedSensors';

type Props = {
  list?: ScannedSensor[];
  sensorsWhitelist?: SensorsWhitelist['uuids'];
  onSensorAdd: (sensorUuid: string) => void;
  isLoading: boolean;
};
const SensorsNearbyTable = ({
  list = [],
  onSensorAdd,
  sensorsWhitelist = [],
  isLoading,
}: Props) => {
  const columns = [
    {
      header: 'Sensor Uuid',
      width: '80%',
      cellContent: ({ uuid }) => <span> {uuid}</span>,
    },
    {
      header: 'Actions',
      cellContent: ({ uuid }) => (
        <Button
          variant="text"
          disabled={sensorsWhitelist.indexOf(uuid) !== -1 || isLoading}
          onClick={() => onSensorAdd(uuid)}
        >
          {' '}
          Add to Whitelist <MdExitToApp size={16} />
        </Button>
      ),
    },
  ];

  return <Table columns={columns} data={list} />;
};

export default SensorsNearbyTable;
