import {
  Equipment,
  EquipmentType,
  Sop,
} from '@energybox/react-ui-library/dist/types';
import SiteCardContainer from './SiteCardContainer';
import React, { useRef } from 'react';
import SopDataRow from './SopDataRow';
import {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import { classNames, isDefined } from '@energybox/react-ui-library/dist/utils';
import styles from '../../components/HvacSopTable/HvacSopTable.module.css';
import useDynamicFilter from '../../hooks/useDynamicFilter';
import { renderCorrespondingEquipment } from '../../components/HvacSopTable/EquipmentCellData';
import {
  Loader,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import { SiteHvacData } from '../../reducers/organizations';
import { showNewOrEditHvacSopModal } from '../../actions/sops';
import { useDispatch } from 'react-redux';
import { processHvacSop } from '../../reducers/sop';
import { HvacSop } from '@energybox/react-ui-library/dist/types/Sop';
import { determineIfSopIsInactive } from '../../components/HvacSopTable/HvacSopTable';
import { EdmLabel } from '../../components/EdmFilter/EdmFilter';

type Props = {
  displayItems: SiteHvacData[];
  setEquipmentAffectedCount: React.Dispatch<React.SetStateAction<number>>;
  orgId: number;
  setIdToDelete: React.Dispatch<React.SetStateAction<number | null>>;
  setIsDeleteModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setFeatureHvacSopId: React.Dispatch<React.SetStateAction<number>>;
  featureHvacSopId: number;
};

const RenderSopData: React.FC<Props> = ({
  displayItems,
  setEquipmentAffectedCount,
  orgId,
  setIdToDelete,
  setIsDeleteModalShowing,
  setFeatureHvacSopId,
  featureHvacSopId,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { selectedFilters: equipmentTypeFilters } = useDynamicFilter<number>(
    'eqType',
    value => parseInt(value)
  );
  const { selectedFilters: EdmFilter } = useDynamicFilter('DemandManager');

  return displayItems.length > 0 ? (
    <>
      {displayItems.map((site, index) => {
        const sop: Sop[] = site.sop;
        const networkGroupIds = site.networkGroupIds;
        const siteId = site.siteId;
        const equipmentsInOrgUnit = site.equipment.map(data => data.equipment);
        const equipmentTypesInOrgUnit = site.equipment.map(
          data => data.equipmentType
        );
        const equipmentTypeIdsInSop = new Set(
          sop.map(({ equipmentTypeIds }) => equipmentTypeIds[0])
        );
        const UniqueEquipmentTypes = equipmentTypesInOrgUnit.filter(
          (eqType, index, self) =>
            index === self.findIndex(t => t.id === eqType.id)
        );
        const filteredEqTypes: EquipmentType[] = [];
        equipmentTypeFilters.forEach(t => {
          UniqueEquipmentTypes.forEach(type => {
            if (type.id == t) {
              filteredEqTypes.push(type);
            }
          });
        });
        const filteredOrUniqueTypes = equipmentTypeFilters.length
          ? filteredEqTypes
          : UniqueEquipmentTypes;

        const noSopEquipmentTypes = filteredOrUniqueTypes.filter(
          ({ id }) => !equipmentTypeIdsInSop.has(id)
        );
        const noSopEquipments = equipmentsInOrgUnit.filter(
          ({ typeId }) => !equipmentTypeIdsInSop.has(typeId)
        );

        const filteredSopData: Sop[] = equipmentTypeFilters.length
          ? sop.filter(data =>
              equipmentTypeFilters.includes(data.equipmentTypeIds[0])
            )
          : sop;

        const finalSopData = EdmFilter.length
          ? filteredSopData.filter(data => {
              const { hvacSettings } = processHvacSop(
                data.components[0] as HvacSop
              );
              const enableEdm = hvacSettings?.enableEdm;
              return EdmFilter.every(
                edm =>
                  (edm === EdmLabel.EDM_ENABLED && enableEdm) ||
                  (edm === EdmLabel.EDM_DISABLED && !enableEdm)
              );
            })
          : filteredSopData;

        const noSopData: CardListRowData[] = noSopEquipments.length
          ? noSopEquipmentTypes.map((equipmentType, index) => {
              let filterdEquipments: Equipment[] = [];
              noSopEquipments?.filter(eq => {
                if (eq.typeId === equipmentType.id) {
                  filterdEquipments.push(eq);
                }
              });
              return {
                key: `${equipmentType.id}`,
                content: (
                  <>
                    <Cell width="2" className={classNames(styles.cardInfoText)}>
                      <div>{equipmentType.title}</div>
                    </Cell>
                    <Cell width="2">
                      <div>
                        {renderCorrespondingEquipment(filterdEquipments)}
                      </div>
                    </Cell>
                    <Cell width="14" className={styles.alignNonSopText}>
                      <div className={(styles.cellColumn, styles.noSopData)}>
                        Incomplete configuration Add SOP Setpoints and Timetable
                      </div>
                    </Cell>
                    <Cell width="1">
                      <MenuDropdown>
                        <MenuDropdownItem
                          onSelect={() => {
                            setEquipmentAffectedCount(
                              filterdEquipments?.length || 0
                            );
                            setFeatureHvacSopId(-1);
                            dispatch(
                              showNewOrEditHvacSopModal(
                                'new',
                                siteId,
                                equipmentType.id
                              )
                            );
                          }}
                        >
                          Add SOP
                        </MenuDropdownItem>
                      </MenuDropdown>
                    </Cell>
                  </>
                ),
              };
            })
          : [];

        const sopData: CardListRowData[] = finalSopData
          .sort(
            (itemA, itemB) =>
              itemA.equipmentTypeIds[0] - itemB.equipmentTypeIds[0]
          )
          .map(item => {
            const { hvacSettings } = processHvacSop(
              item.components[0] as HvacSop
            );

            const {
              hvacSchedules,
              enableEdm,
              enableLocalAdjustment,
              setPointLimitDelta,
              overrideTimer,
            } = hvacSettings || {};

            const isInactiveSop = determineIfSopIsInactive(item, sop, orgId);
            return {
              key: `${item.id}`,
              isDisabled: isInactiveSop,
              content: (
                <>
                  {hvacSchedules?.map((timeSlot, index) => (
                    <>
                      <React.Fragment
                        key={`sopId${item.id}TimetableRow${index}`}
                      >
                        <SopDataRow
                          siteId={siteId}
                          sop={item}
                          timeSlot={timeSlot}
                          enableLocalAdjustment={enableLocalAdjustment}
                          setPointLimitDelta={setPointLimitDelta}
                          overrideTimer={overrideTimer}
                          enableEdm={enableEdm}
                          index={index}
                          isInactiveSop={isInactiveSop}
                          setEquipmentAffectedCount={setEquipmentAffectedCount}
                          equipmentsInOrgUnit={equipmentsInOrgUnit}
                          setFeatureHvacSopId={setFeatureHvacSopId}
                          equipmentTypesInOrgUnit={filteredOrUniqueTypes}
                          setIdToDelete={setIdToDelete}
                          setIsDeleteModalShowing={setIsDeleteModalShowing}
                        />
                      </React.Fragment>
                    </>
                  ))}
                </>
              ),
            };
          });

        return (
          <div key={`hvacSopCard${siteId}`} ref={inputRef}>
            <SiteCardContainer
              site={site}
              siteId={siteId}
              sopData={sopData}
              noSopData={noSopData}
              orgId={orgId}
              featureHvacSopId={featureHvacSopId}
              networkGroupIds={networkGroupIds}
            ></SiteCardContainer>
          </div>
        );
      })}
    </>
  ) : (
    <Loader size={18} />
  );
};

export default RenderSopData;
