import {
  Loader,
  Select,
  SelectItem,
  Table,
} from '@energybox/react-ui-library/dist/components';
import { SensorIntervals } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';

import React from 'react';
import { connect } from 'react-redux';
import { changeSensorReportingInterval } from '../../actions/sensors';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import DeviceOnlineState from '../../containers/DeviceStatus/DeviceOnlineState';
import { ApplicationState } from '../../reducers';
import { DeviceStatusById } from '../../reducers/deviceStatus';
import {
  IsReportIntervalUpdatingBySensorId,
  PairedSensor,
} from '../../reducers/pairedSensors';

interface OwnProps {
  sensorsPairedList?: PairedSensor[];
  gatewayUuid: string;
}

interface Props extends OwnProps {
  subscribeToDeviceStatus: (vendor: string, uuid: string, id: string) => void;
  unsubscribeFromDeviceStatus: (
    vendor: string,
    uuid: string,
    id: string
  ) => void;
  deviceStatusById: DeviceStatusById;
  changeSensorReportingInterval: (id: string, intervalMs: number) => void;
  isReportIntervalUpdatingBySensorId: IsReportIntervalUpdatingBySensorId;
}

class PairedSensorsActionsTable extends React.Component<Props> {
  componentDidMount() {
    const sensorsPairedList = this.props.sensorsPairedList || [];
    sensorsPairedList.forEach(({ uuid, id }) =>
      this.props.subscribeToDeviceStatus('energybox', uuid, id)
    );
  }

  componentWillReceiveProps(nextProps) {
    const currentList = this.props.sensorsPairedList || [];
    const newList = nextProps.sensorsPairedList || [];
    const addSub = R.difference(newList, currentList);
    const removeSub = R.difference(currentList, newList);

    addSub.forEach(({ uuid, id }) =>
      this.props.subscribeToDeviceStatus('energybox', uuid, id)
    );
    removeSub.forEach(({ uuid, id }) =>
      this.props.unsubscribeFromDeviceStatus('energybox', uuid, id)
    );
  }

  componentWillUnmount() {
    (this.props.sensorsPairedList || []).forEach(({ uuid, id }) => {
      this.props.unsubscribeFromDeviceStatus('energybox', uuid, id);
    });
  }

  render() {
    const {
      sensorsPairedList = [],
      changeSensorReportingInterval,
      deviceStatusById,
      isReportIntervalUpdatingBySensorId,
    } = this.props;

    const columns = [
      {
        header: 'Sensor Uuid',
        width: '35%',
        cellContent: ({ uuid }) => {
          // if(!deviceStatusById[uuid]) return uuid
          // const sensorType = (deviceStatusById[uuid].types || [] )[0];
          return <span>{uuid}</span>;
          // return <span style={{ color: "var(ambient-base)"}}>
          // {/* <IconSensorTypeFactory size={16} id={sensorType} />  */}
          // </span>
        },
      },
      {
        header: 'Status',
        width: '30%',
        cellContent: ({ uuid, id }) => (
          // TODO REMOVE THIS INFAVOR FOR A COMPONENT THAT DOESNT SUB AGAIN
          <DeviceOnlineState
            devices={[
              {
                id,
                uuid,
                vendor: 'energybox',
              },
            ]}
          />
        ),
      },

      // This seems to not be correct
      // only works for
      //   {
      //     header: 'Refresh Config',
      //     cellContent: ({ uuid, id }) => (
      //       <Button
      //       onClick={() => emitConfig(id)}
      //       variant="text"
      //     >
      //       {' '}
      //      <MdAutorenew size={16} />
      //     </Button>
      //     ), MdAutorenew
      //   },
      //   {
      //     header: 'Refresh Status',
      //     cellContent: ({ uuid, id }) => (
      //       <Button
      //       onClick={() => emitStatus(id)}
      //       variant="text"
      //     >
      //       {' '}
      //      <MdAutorenew size={16} />
      //     </Button>
      //     ),
      //   },
      {
        header: 'Reporting Interval',
        width: '35%',
        cellContent: ({ id, uuid }) => {
          const isUpdating = isReportIntervalUpdatingBySensorId[id];
          const interval =
            deviceStatusById[String(id)]?.interval ||
            deviceStatusById[String(uuid)]?.interval;

          const selectedLabel = SensorIntervals.find(
            ({ value }) => value === interval
          )?.label;

          if (isUpdating || !selectedLabel) {
            return <Loader size={14} />;
          }

          return (
            <Select variant={'select'} title={selectedLabel}>
              {SensorIntervals.map(({ label, value }) => (
                <SelectItem
                  key={label}
                  isSelected={label === selectedLabel}
                  onSelect={() => {
                    changeSensorReportingInterval(String(id), value);
                  }}
                >
                  {label}
                </SelectItem>
              ))}
            </Select>
          );
        },
      },
    ];
    return <Table columns={columns} data={sensorsPairedList} />;
  }
}

const mapStateToProps = ({
  deviceStatusById,
  pairedSensors,
}: ApplicationState) => ({
  deviceStatusById,
  isReportIntervalUpdatingBySensorId:
    pairedSensors.isReportIntervalUpdatingBySensorId,
});

const mapDispatchToProps = (dispatch: any, { gatewayUuid }: OwnProps) => ({
  subscribeToDeviceStatus: (vendor, uuid, id) => {
    dispatch(subscribeToDeviceStatus(vendor, uuid, id));
  },
  unsubscribeFromDeviceStatus: (vendor, uuid, id) => {
    dispatch(unsubscribeFromDeviceStatus(vendor, uuid, id));
  },
  changeSensorReportingInterval: (sensorId, intervalMs) => {
    dispatch(changeSensorReportingInterval(sensorId, gatewayUuid, intervalMs));
  },
  //   emitConfig: ( sensorId ) => {
  //     dispatch(emitConfig(sensorId, gatewayUuid));
  //   },
  //   emitStatus: ( sensorId ) => {
  //     dispatch(emitStatus(sensorId, gatewayUuid));
  //   }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PairedSensorsActionsTable);
