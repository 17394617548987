import * as R from 'ramda';
import { Actions } from '../actions/distribution_panel';
import {
  GenericErrors,
  ResourceType,
  DistributionPanel,
  DistributionPanelType,
  EnergySource,
} from '@energybox/react-ui-library/dist/types';
import {
  mapValues,
  mapArrayToObject,
  values,
} from '@energybox/react-ui-library/dist/utils';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import { spacesFromApiResponse } from './spaces';

export interface EditableFields {
  title: string;
  description: string;
  spaceId: number;
  breakerSlots: number;
  type: DistributionPanelType;
  energySource: EnergySource;
  mdp: boolean;
}

export const editableFieldKeys = [
  'title',
  'description',
  'spaceId',
  'breakerSlots',
  'type',
  'energySource',
  'mdp',
];

export const editableFields = (distributionPanel: object) =>
  R.pick(editableFieldKeys, distributionPanel);

export type EditDistributionPanel = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

export const newDistributionPanelFields = {
  title: '',
  description: '',
  spaceId: -1,
  breakerSlots: '',
  type: DistributionPanelType.US_VERTICAL_THREE_PHASE,
  energySource: EnergySource.SPLIT_PHASE,
  mdp: false,
};

const newDistributionPanel = {
  isLoading: false,
  isChanged: false,
  fields: newDistributionPanelFields,
  formErrors: formValidationErrors(
    'distributionPanel',
    newDistributionPanelFields
  ),
  formErrorsVisible: false,
  apiError: {},
};

export type DistributionPanelsById = {
  [id: string]: DistributionPanel;
};

export type DistributionPanelsBySiteId = {
  [siteId: string]: DistributionPanel[];
};

export type DistributionPanelsBySpaceId = {
  [id: string]: DistributionPanel[];
};

export type ActionsLoadingStatus = {
  [Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING]?: boolean;
};

export interface DistributionPanels {
  distributionPanelsById: DistributionPanelsById;
  distributionPanelsBySiteId: DistributionPanelsBySiteId;
  showNewDistributionPanelModal: boolean;
  editById: EditById;
  distributionPanelsBySpaceId: DistributionPanelsBySpaceId;
  showDeleteDistributionPanelModal: boolean;
  primedForDelete: number;
  apiError: ApiError;
  loadingStatusByAction: ActionsLoadingStatus;
  newlyInstalledBreakerId: number | undefined;
  distributionPanelApiError: ApiError;
}

export const initialState = {
  distributionPanelsById: {},
  distributionPanelsBySiteId: {},
  showNewDistributionPanelModal: false,
  editById: {},
  distributionPanelsBySpaceId: {},
  showDeleteDistributionPanelModal: false,
  primedForDelete: -1,
  apiError: {},
  loadingStatusByAction: {},
  newlyInstalledBreakerId: undefined,
  distributionPanelApiError: {},
};

export type EditById = {
  [id: string]: EditDistributionPanel;
};

const distributionPanelFromApiResponse = (data: any) => ({
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  breakerArrangement: data.breakerArrangement,
  breakerColumns: data.breakerColumns,
  breakerSlots: data.breakerSlots,
  breakers: data.breakers,
  createdAt: data.createdAt,
  description: data.description || '',
  energySource: data.energySource,
  id: data.id,
  legacyId: data.legacyId || undefined,
  space: spacesFromApiResponse(data.space),
  spaceId: data.spaceId,
  title: data.title,
  type: data.type,
  updatedAt: data.updatedAt || undefined,
  images: data.images || [],
  mdp: data.mdp,
  mainBreaker: data.mainBreaker,
});

export const sortedDistributionPanel = ({
  distributionPanelsById,
}: DistributionPanelsById) =>
  values(distributionPanelsById).sort((a, b) => a.title.localeCompare(b.title));

const distributionPanels = (
  state: DistributionPanels = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING,
        ],
        true,
        state
      );

    case Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_ERROR:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING,
        ],
        false,
        state
      );

    case Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['distributionPanelsBySiteId', action.siteId],
          mapValues(action.data, distributionPanelFromApiResponse)
        ),
        R.assoc(
          'distributionPanelsById',
          mapArrayToObject(
            mapValues(action.data, distributionPanelFromApiResponse)
          )
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING,
          ],
          false
        )
      )(state);

    case Actions.RESET_NEWLY_CREATED_BREAKER_ID:
      return {
        ...state,
        newlyInstalledBreakerId: undefined,
      };

    case Actions.INSTALL_CIRCUIT_BREAKER_SUCCESS:
    case Actions.MOVE_BREAKER_IN_PANEL_SUCCESS:
    case Actions.GET_DISTRIBUTION_PANEL_SUCCESS: {
      let distributionPanel = distributionPanelFromApiResponse(action.data);

      let updatedState = R.pipe(
        R.assocPath(
          ['distributionPanelsById', action.data.id],
          distributionPanel
        ),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(distributionPanel),
          apiError: {},
        }),
        R.assoc('distributionPanelApiError', {})
      )(state);

      if (action.type === Actions.INSTALL_CIRCUIT_BREAKER_SUCCESS) {
        const currentData = state.distributionPanelsById[action.data.id];
        const currentBreakerIds: number[] = (currentData?.breakers || []).map(
          ({ breaker }) => breaker.id
        );
        const updatedBreakerIds: number[] = action.data.breakers.map(
          ({ breaker }) => breaker.id
        );
        let difference = updatedBreakerIds.filter(
          x => !currentBreakerIds.includes(x)
        );
        const newBreakerId: number | undefined = difference[0];
        updatedState = R.assocPath(
          ['newlyInstalledBreakerId'],
          newBreakerId,
          updatedState
        );
      }

      return updatedState;
    }

    case Actions.GET_DISTRIBUTION_PANEL_LOADING:
      return R.assoc('distributionPanelApiError', {}, state);

    case Actions.GET_DISTRIBUTION_PANEL_ERROR:
      return R.assoc('distributionPanelApiError', action.data, state);

    case Actions.GET_DISTRIBUTION_PANELS_BY_SPACE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(['distributionPanelsBySpaceId', action.id], action.data),
        R.assoc(
          'distributionPanelsById',
          R.mergeRight(
            R.view(R.lensProp('distributionPanelsById'), state),
            mapArrayToObject(
              mapValues(action.data, distributionPanelFromApiResponse)
            )
          )
        )
      )(state);

    case Actions.TOGGLE_NEW_DISTRIBUTION_PANEL_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newDistributionPanel),
        R.assoc('showNewDistributionPanelModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('distributionPanel', updatedField)
        )
      )(state);

    case Actions.CREATE_DISTRIBUTION_PANEL_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_DISTRIBUTION_PANEL_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['distributionPanelsById', action.data.id],
          distributionPanelFromApiResponse(action.data)
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewDistributionPanelModal', false)
      )(state);

    case Actions.CREATE_DISTRIBUTION_PANEL_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.DELETE_PICTURE_LOADING:
    case Actions.PATCH_DISTRIBUTION_PANEL_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_DISTRIBUTION_PANEL_SUCCESS:
      const patchedDistributionPanel = distributionPanelFromApiResponse(
        action.data
      );
      return R.pipe(
        R.assocPath(
          ['distributionPanelsById', action.id],
          patchedDistributionPanel
        ),
        R.assocPath(
          ['editById', action.id, 'fields'],
          editableFields(action.data)
        ),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.PATCH_DISTRIBUTION_PANEL_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_DISTRIBUTION_PANEL: {
      var fields = editableFields(
        R.path(['distributionPanelsById', action.id], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('distributionPanel', fields)
        ),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);
    }

    case Actions.DELETE_DISTRIBUTION_PANEL_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_DISTRIBUTION_PANEL_SUCCESS: {
      if (action.data) {
        return R.pipe(
          R.dissocPath(['distributionPanelsById', action.id]),
          R.dissocPath(['editById', action.id]),
          R.assoc('showDeleteDistributionPanelModal', false),
          R.assoc('primedForDelete', -1)
        )(state);
      }
      return state;
    }

    case Actions.UPLOAD_PICTURE_ERROR:
    case Actions.DELETE_PICTURE_ERROR:
    case Actions.DELETE_DISTRIBUTION_PANEL_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.UPLOAD_PICTURE_LOADING:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], {}),
        R.assocPath(['editById', action.id, 'isLoading'], true)
      )(state);

    case Actions.TOGGLE_DELETE_DISTRIBUTION_PANEL_MODAL:
      return R.pipe(
        R.assoc('showDeleteDistributionPanelModal', action.value),
        R.assoc('apiError', {})
      )(state);

    case Actions.UPLOAD_PICTURE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['distributionPanelsById', action.id, 'images'],
          state['distributionPanelsById'][action.id].images.concat(
            action.data.path
          )
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DELETE_PICTURE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['distributionPanelsById', action.id, 'images'],
          state['distributionPanelsById'][action.id].images.filter(path => {
            return path !== action.path;
          })
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.PRIME_FOR_DELETE:
      return R.assoc('primedForDelete', action.id, state);

    case Actions.UNSTAGE_DELETE:
      return R.assoc('primedForDelete', -1, state);

    default:
      return state;
  }
};

export default distributionPanels;
