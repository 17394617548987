import React from 'react';
import {
  classNames,
  formatNumberString,
} from '@energybox/react-ui-library/dist/utils';

import styles from './LiveReadingTable.module.css';

export type RowCellFormatter = {
  label?: string;
  value?: number | string;
  unit?: string;
  decimal?: number;
};

type Props = {
  headers: string[];
  rows: Array<RowCellFormatter[]>;
};

const LiveReadingTable = ({ headers, rows }: Props) => {
  const columnCount = headers.length;
  const headerElement = (
    <div className={styles[`columns_${columnCount}`]}>
      {headers.map((header, index) => (
        <div key={`${header}column${index}`} className={styles.header}>
          {header}
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {headerElement}
      {rows.map(row => {
        return (
          <div
            className={classNames(
              styles[`columns_${columnCount}`],
              styles.valueRow
            )}
          >
            {row.map(cellFormatter => {
              return renderCellContent(cellFormatter);
            })}
          </div>
        );
      })}
    </div>
  );
};

const renderCellContent = ({
  label,
  value,
  unit,
  decimal,
}: RowCellFormatter) => {
  if (typeof value === 'string') {
    return <div className={styles.value}>{value}</div>;
  }
  if (label) {
    return <div className={styles.value}>{label}</div>;
  }
  return (
    <div className={styles.value}>
      {formatNumberString(value, {
        unit,
        numDecimals: decimal,
        useNotAvailableText: true,
      })}
    </div>
  );
};

export default LiveReadingTable;
