import {
  ApiError,
  DistributionPanel,
  EnergyPro,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import { ApplicationState } from '../../../reducers';
import {
  displayFormErrors,
  hideEditCircuitBreakerModal,
  patch,
  updateField,
} from '../../../actions/circuit_breakers';
import { EditableFields } from '../../../reducers/circuit_breakers';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { connect } from 'react-redux';
import ModalFormContent from '../../../components/ModalFormContent';
import SelectBreakerRating from '../../Selects/SelectBreakerRating';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import CircuitBreakerReadings from '../../../components/CircuitBreaker/CircuitBreakerReadings';
import CircuitBreakerPhasorDiagram from '../../../components/CircuitBreaker/CircuitBreakerPhasorDiagram';
import PowerReadings from '../../../components/CircuitBreaker/PowerReadings';
import { Actions as CircuitBreakerActions } from '../../../actions/circuit_breakers';
import { getBreakerToEnergySensorsMapping } from '../../../utils/energyPro';
import styles from './EditCircuitBreakerModal.module.css';

interface OwnProps {
  breakerId: number;
  panelId: number;
  energyPro: EnergyPro;
}

interface Props extends OwnProps {
  onChange: (field: string, value: string | number) => void;
  onSave: (panelId: number) => void;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  formErrorsVisible: boolean;
  displayFormErrors: (id: string) => void;
  apiError: ApiError;
}

const EditCircuitBreakerModal: React.FC<Props> = props => {
  if (!props.isVisible) return null;

  const {
    energyPro,
    fields,
    onChange,
    onClose,
    onSave,
    isLoading,
    formErrors,
    formErrorsVisible,
    displayFormErrors,
    apiError,
  } = props;

  const { title, description, rating } = fields;
  const breakerToEnergySensorsMapping = getBreakerToEnergySensorsMapping(
    energyPro
  );
  const energySensors = breakerToEnergySensorsMapping[props.breakerId];

  const onCircuitBreakerSave = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors(String(props.breakerId));
    } else {
      onSave(props.panelId);
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onCircuitBreakerSave}>
        Save
      </Button>
    </>
  );

  return (
    <div className={styles.modal}>
      <div className={styles.arrow} />
      <Card>
        <CardContent>
          <CardTitle className={styles.title}>Breaker</CardTitle>
          <ModalFormContent>
            <div>
              <Label htmlFor="title">Breaker Name</Label>
            </div>
            <div>
              <InputField
                id="title"
                type="text"
                name="title"
                value={title}
                onChange={e => onChange('title', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.title}
              />
            </div>

            <div>
              <Label htmlFor="description">Description</Label>
            </div>
            <div>
              <InputField
                id="description"
                type="text"
                name="description"
                placeholder=""
                value={description}
                onChange={e => onChange('description', e.currentTarget.value)}
              />
            </div>

            <div>
              <Label htmlFor="rating">Breaker rating (Amperes)</Label>
            </div>
            <div>
              <SelectBreakerRating
                value={rating}
                error={formErrorsVisible && !!formErrors.rating}
                onSelect={selectedRating => onChange('rating', selectedRating)}
              />
            </div>
          </ModalFormContent>

          <CircuitBreakerReadings energySensors={energySensors} />

          <div className={styles.diagramContainer}>
            <CircuitBreakerPhasorDiagram energySensors={energySensors} />
            <PowerReadings energySensors={energySensors} />
          </div>

          {renderAPIerror(
            apiError,
            CircuitBreakerActions.PATCH_CIRCUIT_BREAKER_ERROR
          )}
        </CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = (
  { circuitBreakers }: ApplicationState,
  { breakerId }: OwnProps
) => {
  return {
    isVisible:
      circuitBreakers.showEditCircuitBreakerModal.value &&
      breakerId === circuitBreakers.showEditCircuitBreakerModal.id,
    ...circuitBreakers.editById[breakerId],
  };
};

const mapDispatchToProps = (dispatch, { breakerId, panelId }: OwnProps) => ({
  onClose: () => dispatch(hideEditCircuitBreakerModal()),
  onChange: (field: string, value: string | number) =>
    dispatch(updateField(String(breakerId), field, value)),
  onSave: () => dispatch(patch(panelId, breakerId)),
  displayFormErrors: (breakerId: string) =>
    dispatch(displayFormErrors(breakerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCircuitBreakerModal);
