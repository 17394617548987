import {
  DeviceTypeFilter,
  ExtraShortSkeletonCell,
  MediumLongSkeletonCell,
  SearchBox,
  ShortSkeletonCell,
  Table,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  DeviceType,
  DeviceTypeDisplayText,
  Firmware,
  Gateway,
  GatewayVendorLabel,
  OpacityIndex,
  ResourcePath as RPath,
  ResourceType,
  SortDirection,
  TimeZone,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  genericTableSort,
  SORT_IGNORED_VALUES,
  isDefined,
  global,
} from '@energybox/react-ui-library/dist/utils';
import { Columns as TableColumns } from '@energybox/react-ui-library/dist/components/Table';

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFirmwares, getTimeZones } from '../../actions/app';
import {
  Actions,
  getByResourceId,
  getGateways,
  filterGatewaysBySiteIds,
} from '../../actions/gateways';
import { ApplicationState } from '../../reducers';
import { DeviceStatusById } from '../../reducers/deviceStatus';
import { IsPathLoadingById, PathsById } from '../../reducers/paths';
import { Routes } from '../../routes';
import { PropertyToLabel, Placeholder } from '../../types/global';
import { checkCommonPlural } from '../../util';
import { getDeviceStatusSortingFn } from '../../utils/devices';
import {
  ConnectionType,
  FirmwareVersion,
  RFConnectionType,
} from '../../utils/gateway';
import DeviceOnlineState from '../DeviceStatus/DeviceOnlineState';
import ResourcePath from '../ResourcePath';
import SiteFilter from '../../components/SiteFilter';
import ControlBoardLocalOverrideLabel from '../../components/ControlBoardLocalOverrideLabel';
import ThermostatLocalOverrideLabel from '../../components/ThermostatLocalOverrideLabel';
import history from '../../history';
import { TableWrapper } from '../../components/ui/Table';
import theme from '../../theme';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import FiltersContainer from '../../components/Filters/FiltersContainer/FiltersContainer';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';
import SiteGroupFilter from '../../components/SiteGroupFilter';
import useDynamicFilter from '../../hooks/useDynamicFilter';
import useSiteGroupsFilter from '../../hooks/useSiteGroupsFilter';
import useSiteFilter from '../../hooks/useSiteFilter';
import usePaginationFilter from '../../hooks/usePaginationFilter';
import { getResourcePathsByIds } from '../../actions/paths';
import gateways from '../../reducers/gateways';
import ResourcePaths from '../ResourcePath/ResourcePaths';
import ThermostatDisplayNameLabel from '../../components/ThermostatDisplayNameLabel';

interface OwnProps {
  siteId?: number;
}
interface Props extends OwnProps {
  getByResourceId: typeof getByResourceId;
  getGateways: typeof getGateways;
  getFirmwares: typeof getFirmwares;
  filterGatewaysBySiteIds: typeof filterGatewaysBySiteIds;
  getResourcePathsByIds: typeof getResourcePathsByIds;
  getTimeZones: typeof getTimeZones;
  setDeviceType: (deviceType: DeviceType[]) => void;
  setPagination?: (
    page: number | undefined,
    rowLimit: number | undefined
  ) => void;
  currentPage: number | undefined;
  rowLimit: number | undefined;
  selectedSiteFilters: string[];
  selectedDeviceType: DeviceType[];
  gateways: (Gateway | ControlBoard)[];
  releaseFirmwares?: Firmware[];
  timezones?: TimeZone[];
  getGatewaysIsLoading: boolean | undefined;
  resourcePathsById: PathsById;
  gatewayIdsFilteredBySites: number[];
  deviceStatusById: DeviceStatusById;
  viewportType: ViewportTypes;
  siteGroupWithoutSites: boolean;
}

interface State {
  query: string;
  gatewaysLoadingGuard: boolean;
}

const withDynamicFilter = WrappedComponent => {
  return props => {
    const dataLength = useMemo(() => props.gateways?.length, [
      props?.gateways?.length,
    ]);

    const { currentPage, rowLimit, setPagination } = usePaginationFilter(
      dataLength
    );

    const {
      selectedFilters: selectedDeviceType,
      setFilter: setDeviceType,
    } = useDynamicFilter<DeviceType[]>('deviceType');
    const { siteGroupWithoutSites } = useSiteGroupsFilter();
    const { selectedSiteFilters } = useSiteFilter();

    return (
      <WrappedComponent
        setPagination={setPagination}
        rowLimit={rowLimit}
        currentPage={currentPage}
        siteGroupWithoutSites={siteGroupWithoutSites}
        selectedSiteFilters={selectedSiteFilters}
        setDeviceType={setDeviceType}
        selectedDeviceType={selectedDeviceType}
        {...props}
      />
    );
  };
};

class GatewaysTable extends React.Component<Props, State> {
  static defaultProps = {
    gateways: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
      gatewaysLoadingGuard: true,
    };
  }

  componentDidMount() {
    const {
      getGateways,
      getFirmwares,
      getByResourceId,
      siteId,
      gateways,
      getResourcePathsByIds,
    } = this.props;
    setTimeout(() => {
      this.setState({ gatewaysLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams<string>(history, 'query', '');
    const savedPage = getUrlStateParams<number>(history, 'page', 1);

    this.setState({ query: savedQuery });

    if (siteId) {
      getByResourceId(siteId);
    } else {
      if (!this.props.selectedSiteFilters.length) {
        getGateways();
      }
    }

    getFirmwares({ types: 'RELEASE' });
    if (gateways.length > 0) {
      const gatewayIds = gateways.map(gateway => gateway.id);
      getResourcePathsByIds(gatewayIds);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: Readonly<State>) {
    const {
      filterGatewaysBySiteIds,
      selectedSiteFilters,
      gatewayIdsFilteredBySites,
      getResourcePathsByIds,
      gateways,
    } = this.props;

    if (
      prevProps.selectedSiteFilters.join('') !== selectedSiteFilters.join('') ||
      prevProps.gatewayIdsFilteredBySites.join('') !==
        gatewayIdsFilteredBySites.join('') ||
      (selectedSiteFilters?.length && !gatewayIdsFilteredBySites?.length)
    ) {
      filterGatewaysBySiteIds(selectedSiteFilters);
    }

    if (
      prevProps.gateways !== gateways &&
      gateways.length > 0 &&
      prevProps.gateways.length !== gateways.length
    ) {
      const gatewayIds = gateways.map(gateway => gateway.id);
      getResourcePathsByIds(gatewayIds);
    }
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  render() {
    const {
      siteId,
      gateways,
      releaseFirmwares,
      getGatewaysIsLoading,
      resourcePathsById,
      gatewayIdsFilteredBySites,
      deviceStatusById,
      viewportType,
      selectedDeviceType,
      siteGroupWithoutSites,
      selectedSiteFilters,
      currentPage,
      rowLimit,
      setPagination,
    } = this.props;

    const isMobile = viewportType === ViewportTypes.MOBILE;
    const selectedThermostatTypeFilters = [
      'VENSTAR_THERMOSTAT',
      'ENERGYBOX_THERMOSTAT',
    ];
    const selectedSiteControllerTypeFilters = [
      'ENERGYBOX_CB',
      'ENERGYBOX_CB_US_10',
      'ENERGYBOX_CB_US_6',
      'ENERGYBOX_CB_EU_6',
    ];
    const { query, gatewaysLoadingGuard } = this.state;
    const isLoading = getGatewaysIsLoading || gatewaysLoadingGuard;
    const hasSiteFilter = !isDefined(siteId);

    const queryFilteredGateways =
      query && query.length >= 3
        ? gateways.filter(gateway =>
            hasSubstr(
              `${gateway.title}${gateway.uuid}${
                gateway.space ? gateway.space.title : ''
              }`,
              query
            )
          )
        : gateways;

    const filterByDeviceAndGatewayModelType = (
      item: Gateway | ControlBoard
    ) => {
      const hasSelectedDeviceFilters = selectedDeviceType.length > 0;
      const isThermostat =
        hasSelectedDeviceFilters &&
        selectedDeviceType.includes(DeviceType.THERMOSTAT);
      const isSiteController =
        hasSelectedDeviceFilters &&
        selectedDeviceType.includes(DeviceType.ENERGYBOX_CB);

      if (isThermostat && isSiteController) {
        // Both thermostat and site controller filters are selected, so include only these two device types
        const filteredByThermostat =
          selectedThermostatTypeFilters.length > 0 &&
          selectedThermostatTypeFilters.includes(item.model as DeviceType);
        const filteredBySiteController =
          selectedSiteControllerTypeFilters.length > 0 &&
          selectedSiteControllerTypeFilters.includes(item.model as DeviceType);
        const filteredByDevice = selectedDeviceType.includes(
          item.model as DeviceType
        );

        return (
          filteredByDevice || filteredByThermostat || filteredBySiteController
        );
      }

      if (isThermostat) {
        const filteredByThermostat =
          selectedThermostatTypeFilters.length > 0 &&
          selectedThermostatTypeFilters.includes(item.model as DeviceType);
        const filteredByDevice = selectedDeviceType.includes(
          item.model as DeviceType
        );

        return filteredByDevice || filteredByThermostat;
      } else if (isSiteController) {
        const filteredBySiteController =
          selectedSiteControllerTypeFilters.length > 0 &&
          selectedSiteControllerTypeFilters.includes(item.model as DeviceType);
        const filteredByDevice = selectedDeviceType.includes(
          item.model as DeviceType
        );

        return filteredByDevice || filteredBySiteController;
      } else {
        return (
          !hasSelectedDeviceFilters ||
          selectedDeviceType.includes(item.model as DeviceType)
        );
      }
    };

    const filteredGateways = queryFilteredGateways
      .filter(item =>
        hasSiteFilter && selectedSiteFilters.length > 0
          ? gatewayIdsFilteredBySites.includes(item.id)
          : true
      )
      .filter(item => {
        return filterByDeviceAndGatewayModelType(item);
      });

    const columns: TableColumns<Gateway>[] = [
      {
        header: 'Device Name',
        width: '20%',
        cellContent: (gateway: Gateway) => (
          <>
            <Link to={`${Routes.DEVICES}${Routes.GATEWAYS}/${gateway.id}`}>
              {gateway.title}
            </Link>
            {gateway.resourceType === ResourceType.ENERGYBOXTHERMOSTAT && (
              <ThermostatDisplayNameLabel thermostatId={gateway.id} />
            )}
          </>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Gateway, b: Gateway, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Device Type',
        width: '15%',
        cellContent: (gateway: Gateway) => (
          <>
            <div>{DeviceTypeDisplayText[gateway.model] || ''}</div>
            <div>
              {gateway.resourceType === ResourceType.CONTROLBOARD && (
                <ControlBoardLocalOverrideLabel controlBoardId={gateway.id} />
              )}
              {gateway.resourceType === ResourceType.VENSTARTHERMOSTAT && (
                <ThermostatLocalOverrideLabel thermostat={gateway} />
              )}
            </div>
          </>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Gateway, b: Gateway, sortDirection: SortDirection) => {
          const getDeviceType = (g: Gateway) => DeviceTypeDisplayText[g.model];
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getDeviceType
          );
        },
      },
      {
        header: 'Status / Last Check-In',
        width: '15%',
        cellContent: (gateway: Gateway) => {
          const path: RPath[] | undefined = resourcePathsById[gateway.id];
          let ianaTimeZoneCode: string | undefined = undefined;
          if (path) {
            const siteNode = path.find(
              ({ type }) => type === ResourceType.SITE
            );
            ianaTimeZoneCode = siteNode?.timeZone;
          }
          return (
            <DeviceOnlineState
              ianaTimeZoneCode={ianaTimeZoneCode}
              devices={[
                {
                  id: gateway.id,
                  uuid: gateway.uuid,
                  vendor: gateway.vendor,
                },
              ]}
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: getDeviceStatusSortingFn(deviceStatusById),
      },
      {
        header: 'Uuid / Vendor',
        width: '15%',
        cellContent: (gateway: Gateway) => {
          const isVenstarThermostat =
            gateway.resourceType === ResourceType.VENSTARTHERMOSTAT;

          return (
            <div>
              <div>
                <strong>{gateway.uuid}</strong>
              </div>
              {isVenstarThermostat
                ? gateway.venstarThermostatModel || global.NOT_AVAILABLE
                : GatewayVendorLabel[gateway.vendor]}
            </div>
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Gateway, b: Gateway, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'uuid',
          ]);
        },
      },
      {
        header: 'Firmware Version',
        width: '10%',
        defaultSortDirection: SortDirection.DESC,
        cellContent: (gateway: Gateway) => (
          <FirmwareVersion
            model={gateway.model}
            gatewayInfo={gateway.gatewayInfo}
            releaseFirmwares={releaseFirmwares}
          />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ExtraShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Gateway, b: Gateway, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'gatewayInfo',
            'firmwareVersion',
          ]);
        },
      },
      {
        header: `${PropertyToLabel.siteId} / ${PropertyToLabel.spaceId}`,
        width: '15%',
        cellContent: (gateway: Gateway) => (
          <ResourcePaths
            resourceId={gateway.id}
            resourceType={ResourceType.SPACE}
          />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Gateway, b: Gateway, sortDirection: SortDirection) => {
          const getLocation = (g: Gateway) => {
            return (resourcePathsById[g.id] || [])
              .map((p: RPath) => p.title)
              .join();
          };

          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getLocation
          );
        },
      },
      {
        header: 'Connection Type',
        width: '5%',
        cellContent: (gateway: Gateway) =>
          DeviceTypeDisplayText[gateway.model] === 'Thermostat' ||
          DeviceTypeDisplayText[gateway.model] === 'EnergyPro II' ? (
            <RFConnectionType vendor={gateway.vendor} deviceId={gateway.uuid} />
          ) : (
            <ConnectionType gatewayStatus={gateway.gatewayStatus} />
          ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ExtraShortSkeletonCell opacityIndex={rowIndex} />
        ),
      },
    ];

    return (
      <DynamicContentWrapper>
        <PageContentHeader header="All Devices">
          <SearchBox
            placeholder={Placeholder.seachBox}
            onChange={this.handleSearchChange}
            query={query}
            width={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            widthActive={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            error={filteredGateways.length === 0}
          />
        </PageContentHeader>
        <FiltersContainer>
          <DeviceTypeFilter
            setFilter={this.props.setDeviceType}
            selectedDeviceTypes={selectedDeviceType}
          />
          {hasSiteFilter && <SiteFilter />}
          {hasSiteFilter && <SiteGroupFilter />}
        </FiltersContainer>
        <TableWrapper
          header={checkCommonPlural('Device', filteredGateways.length)}
          pageNavHidden={filteredGateways.length == 0 ? true : false}
        >
          <Table
            listView
            columns={columns}
            data={!siteGroupWithoutSites ? filteredGateways : []}
            uniqueKeyField="id"
            dataIsLoading={isLoading}
            rowLimitFromPaginationHook={rowLimit}
            currentPageFromPaginationHook={currentPage}
            setPagination={setPagination}
          />
        </TableWrapper>
      </DynamicContentWrapper>
    );
  }
}

const mapStateToProps = (
  { gateways, resourcePaths, deviceStatusById, app }: ApplicationState,
  { siteId }: OwnProps
) => ({
  gateways: (siteId
    ? (gateways.gatewayIdsByResourceId[siteId] || []).map(
        id => gateways.gatewaysById[id]
      )
    : Object.values(gateways.gatewaysById)
  ).filter(item => item),
  releaseFirmwares: app.firmwares,
  deviceStatusById: deviceStatusById,
  resourcePathsById: resourcePaths.byIds,
  gatewayIdsFilteredBySites: gateways.gatewayIdsFilteredBySites,
  getGatewaysIsLoading:
    gateways.loadingStatusByAction[Actions.GET_GATEWAYS_LOADING] ||
    Object.values(
      gateways.loadingStatusByAction[Actions.GET_GATEWAYS_BY_SITE_ID_LOADING] ||
        {}
    ).some(loading => loading),
});

const mapDispatchToProps = {
  getGateways,
  getFirmwares,
  getTimeZones,
  getByResourceId,
  filterGatewaysBySiteIds,
  getResourcePathsByIds,
};

export default withViewportType(
  withDynamicFilter(connect(mapStateToProps, mapDispatchToProps)(GatewaysTable))
);
