import {
  Button,
  InputField,
  Table,
  MediaElement,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import {
  ResourceType,
  SensorsWhitelist,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { connect } from 'react-redux';
import { getSensors } from '../../actions/sensors';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import ValueChip from '../../components/ValueChip';
import DeviceOnlineState from '../../containers/DeviceStatus/DeviceOnlineState';
import { ApplicationState } from '../../reducers';
import { DeviceStatusById } from '../../reducers/deviceStatus';
import { PairedSensor } from '../../reducers/pairedSensors';
import { SensorsById } from '../../reducers/sensors';
import {
  BatteryStatus,
  SensorTypeIconWithHoverText,
  SignalStatus,
} from '../../utils/sensor';
import FeatureFlag from '../FeatureFlag';
import DeviceLocation from '../ResourceLocation';
import ResourcePath from '../ResourcePath/ResourcePath';
import styles from './WhitelistTable.module.css';
import ChainIcon from './ChainIcon';
import { isEBThermostat } from '../../utils/ebThermostat';
import {
  EthernetIcon,
  ThermostatIcon,
} from '@energybox/react-ui-library/dist/icons';

type OwnProps = {
  list?: SensorsWhitelist['uuids'];
  sensorsPairedList?: PairedSensor[];
  onRemoveSensor: (sensorUuid: string) => void;
  isLoading: boolean;
  onSensorAdd: (uuid: string) => void;
  ianaTimeZoneCode?: string;
};

type Props = {
  subscribeToDeviceStatus: typeof subscribeToDeviceStatus;
  unsubscribeFromDeviceStatus: typeof unsubscribeFromDeviceStatus;
  getSensors: typeof getSensors;
  deviceStatusById: DeviceStatusById;
  sensorsById: SensorsById;
  isThermostatSensorPage?: boolean;
} & OwnProps;

type State = {
  addSensorUuid: string;
};

class WhitelistTable extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      addSensorUuid: '',
    };
  }
  componentDidMount() {
    const { list } = this.props;
    const currentList = list || [];
    if (currentList.length) {
      this.getSensorsFromUuids('energybox', currentList);
    }
    currentList.forEach(uuid => {
      this.props.subscribeToDeviceStatus('energybox', uuid, uuid);
    });
  }

  componentDidUpdate(prevProps) {
    const addSub = R.difference(this.props.list, prevProps.list);
    if (addSub.length > 0) {
      this.getSensorsFromUuids('energybox', addSub);
    }
  }

  componentWillReceiveProps(nextProps) {
    const currentList = this.props.list || [];
    const newList = nextProps.list || [];
    const addSub = R.difference(newList, currentList);
    const removeSub = R.difference(currentList, newList);

    addSub.forEach(uuid => {
      this.props.subscribeToDeviceStatus('energybox', uuid, uuid);
    });
    removeSub.forEach(uuid => {
      this.props.unsubscribeFromDeviceStatus('energybox', uuid, uuid);
    });
  }

  componentWillUnmount() {
    (this.props.list || []).forEach(uuid => {
      this.props.unsubscribeFromDeviceStatus('energybox', uuid, uuid);
    });
  }

  getSensorsFromUuids = async (vendor: string, uuids: string[]) => {
    const iam = process.env.REACT_APP_API_BASE_URL;
    const apiBase = '/api/v1/sensors';
    const promises = uuids.map(uuid =>
      fetch(`${iam}${apiBase}/lookup/${vendor}/${uuid}`)
    );
    const responses = await Promise.all(promises);
    const sensorIds = await Promise.all(responses.map(res => res.text()));
    this.props.getSensors({ ids: sensorIds });
  };

  render() {
    const {
      list = [],
      onRemoveSensor,
      sensorsPairedList = [],
      isLoading,
      deviceStatusById,
      sensorsById,
      ianaTimeZoneCode,
      isThermostatSensorPage = false,
    } = this.props;

    const { addSensorUuid } = this.state;
    const columns = [
      {
        header: 'Type',
        cellContent: uuid => {
          const status = deviceStatusById[uuid]!;
          if (!status || !status.onlineState) return global.NOT_AVAILABLE;
          if (isEBThermostat(uuid)) {
            return (
              <MediaElement
                icon={<ThermostatIcon variant="small" size="20" />}
                title={''}
              />
            );
          }
          let sensor;
          if (isDefined(status.id)) {
            sensor = sensorsById[status.id.toString()];
          }
          let sensorTypes;
          if (!sensor) {
            sensorTypes = status.types;
          } else {
            sensorTypes = sensor.types;
          }
          return (
            sensorTypes && (
              <MediaElement
                icon={sensorTypes.map(sensorType => (
                  <SensorTypeIconWithHoverText
                    key={sensorType}
                    sensorType={sensorType}
                  />
                ))}
                title={''}
              />
            )
          );
        },
      },
      {
        header: 'Sensor Uuid',
        cellContent: uuid => {
          if (!isThermostatSensorPage) {
            return uuid;
          }
          const status = deviceStatusById[uuid]!;
          if (!deviceStatusById[uuid]) {
            return uuid;
          }
          const sensor = isDefined(status.id)
            ? sensorsById[status.id.toString()]
            : status;
          if (!sensor) {
            return uuid;
          }
          return (
            sensor && (
              <span>
                {uuid}{' '}
                {isThermostatSensorPage && isDefined(sensor)
                  ? sensor.firmwareVersion
                  : ''}
              </span>
            )
          );
        },
      },
      ...(isThermostatSensorPage == false
        ? [
            {
              header: 'Status',
              cellContent: uuid =>
                // TODO REMOVE THIS INFAVOR FOR A COMPONENT THAT DOESNT SUB AGAIN
                !isThermostatSensorPage && (
                  <DeviceOnlineState
                    ianaTimeZoneCode={ianaTimeZoneCode}
                    devices={[
                      {
                        id: uuid,
                        uuid,
                        vendor: 'energybox',
                      },
                    ]}
                  />
                ),
            },
          ]
        : []),
      {
        header: 'Last Reading',
        cellContent: uuid => {
          const status = deviceStatusById[uuid]!;
          if (!status || !status.id) {
            return global.NOT_AVAILABLE;
          }
          const sensor = isDefined(status.id)
            ? sensorsById[status.id.toString()]
            : null;
          return (
            <>
              {sensor && isDefined(sensor.id) ? (
                <ValueChip
                  sensorId={sensor.id.toString()}
                  types={sensor.types || []}
                  updatedAt={
                    sensor.sensorStatus && sensor.sensorStatus.receivedAt
                  }
                />
              ) : (
                global.NOT_AVAILABLE
              )}
              {isThermostatSensorPage && status?.onlineState && (
                <span className={styles.timestampStyle}>
                  <TimeDistance timestamp={status?.timestamp} />
                </span>
              )}
            </>
          );
        },
      },
      ...(isThermostatSensorPage == false
        ? [
            {
              header: 'Location',
              cellContent: uuid =>
                (!isThermostatSensorPage &&
                  deviceStatusById[uuid] &&
                  deviceStatusById[uuid].id && (
                    <ResourcePath
                      resourceType={ResourceType.SPACE}
                      resourceId={Number(deviceStatusById[uuid].id)}
                    />
                  )) ||
                global.NOT_AVAILABLE,
            },
            {
              header: 'Equipment',
              cellContent: uuid =>
                (!isThermostatSensorPage &&
                  deviceStatusById[uuid] &&
                  deviceStatusById[uuid].id && (
                    <DeviceLocation
                      resourceId={Number(deviceStatusById[uuid].id)}
                      resourceType={ResourceType.EQUIPMENT}
                    />
                  )) ||
                global.NOT_AVAILABLE,
            },
            {
              // TODO FIND REAL PAIRING ICON
              header: 'Paired',
              cellContent: uuid =>
                !isThermostatSensorPage &&
                (sensorsPairedList.find(sen => sen.uuid === uuid) ? (
                  <ChainIcon size={12} />
                ) : (
                  <ChainIcon size={12} disabled />
                )),
            },
          ]
        : []),
      {
        header: 'Signal',
        cellContent: uuid =>
          (deviceStatusById[uuid]?.onlineState && (
            <SignalStatus
              vendor={deviceStatusById[uuid]?.vendor}
              sensorStatus={deviceStatusById[uuid]}
              showSignalStrength={true}
            />
          )) ||
          global.NOT_AVAILABLE,
      },
      {
        header: 'Power',
        cellContent: uuid => {
          return (
            (deviceStatusById[uuid]?.onlineState && !isEBThermostat(uuid) && (
              <BatteryStatus
                vendor={deviceStatusById[uuid]?.vendor}
                sensorStatus={deviceStatusById[uuid]}
                showValue={true}
              />
            )) ||
            (deviceStatusById[uuid]?.onlineState && isEBThermostat(uuid) && (
              <EthernetIcon size={16} />
            )) ||
            global.NOT_AVAILABLE
          );
        },
      },

      {
        header: '',
        cellContent: uuid =>
          !isThermostatSensorPage && (
            <Button
              disabled={isLoading}
              variant="text"
              onClick={() => onRemoveSensor(uuid)}
            >
              {' '}
              Remove from list <MdExitToApp size={16} />
            </Button>
          ),
      },
    ];

    return (
      <div>
        {!isThermostatSensorPage && (
          <FeatureFlag>
            <div className={styles.addSensorContainer}>
              <div className={styles.inputStyle}>
                <InputField
                  placeholder={'AA:BB:CC:DD:EE:FF'}
                  type="text"
                  value={addSensorUuid}
                  onChange={e =>
                    this.setState({ addSensorUuid: e.currentTarget.value })
                  }
                />
              </div>
              <div className={styles.buttonStyle}>
                <Button
                  onClick={() => {
                    this.props.onSensorAdd(addSensorUuid);
                    this.setState({ addSensorUuid: '' });
                  }}
                >
                  {' '}
                  Add Sensor{' '}
                </Button>
              </div>
            </div>
          </FeatureFlag>
        )}
        <Table
          columns={columns}
          data={list}
          highlightAlternateRows={isThermostatSensorPage}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  deviceStatusById,
  resourcePaths,
  sensors: { sensorsById },
}: ApplicationState) => ({
  deviceStatusById,
  sensorsById,
});

const mapDispatchToProps = {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
  getSensors,
};

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistTable);
