import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getThermostatDetailById } from '../../actions/thermostats';
import globalVariables from '@energybox/react-ui-library/dist/utils/global';

type Props = {
  thermostatId: string | number;
};

const ThermostatDisplayNameLabel: React.FC<Props> = ({ thermostatId }) => {
  const dispatch = useDispatch();

  const thermostatDetail = useSelector(({ thermostats }: ApplicationState) => {
    return thermostats.thermostatDetailById[thermostatId];
  });

  useEffect(() => {
    if (!thermostatDetail) {
      dispatch(getThermostatDetailById(thermostatId));
    }
  }, [dispatch, thermostatDetail, thermostatId]);

  return (
    <div>
      {thermostatDetail
        ? thermostatDetail.configuration
          ? thermostatDetail.configuration.equipmentName
          : globalVariables.NOT_AVAILABLE
        : ''}
    </div>
  );
};

export default ThermostatDisplayNameLabel;
