import React, { useEffect } from 'react';
import { ProgressBar } from '@energybox/react-ui-library/dist/components';
import styles from './SignalStrength.module.css';

// normalizeSignal from -100 ~ -20
const normaliseSignal = (value: number) => {
  const min = -100;
  const max = -20;
  if (-value <= min) return 0;
  if (-value >= max) return 100;
  return ((-value - min) * 100) / (max - min);
};

const getSignalColor = (value: number) => {
  if (value > 80) return '#FF514B';
  if (value > 70) return '#FC8017';
  return '#70DA7F';
};

const SignalStrength: React.FC<{ value: number; showValue?: boolean }> = ({
  value,
  showValue = true,
}) => {
  const percentage = normaliseSignal(value);

  useEffect(() => {
    // Determine background color based on percentage and set CSS variable
    let customBackgroundColor = getSignalColor(value);
    document.documentElement.style.setProperty(
      '--customBackgroundColor',
      customBackgroundColor
    );
  }, [percentage]);

  return (
    <>
      {showValue ? `-${value}dBm` : ''}
      <ProgressBar
        perceivedProgress={percentage}
        className={styles.customProgressBar}
        isSignalStrength={true}
        signalStyles={styles.customHighlightBar}
      />
    </>
  );
};

export default SignalStrength;
