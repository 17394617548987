import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  displayFormErrors,
  hideNewDistributionPanelModal,
  updateField,
} from '../../actions/distribution_panel';
import { createWithDistributionPanel as create } from '../../actions/energy_pros';
import {
  displayFormErrors as displayEPFormErrors,
  createNewEnergyPro,
  updateField as updateFieldEPro,
} from '../../actions/energy_pros';
import EditDistributionPanelForm from '../../components/EditDistributionPanelForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/distribution_panels';
import { EditableFields as EProEditableFields } from '../../reducers/energy_pros';
import { CreateNewText } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import EditEnergyProForm from '../../components/EditEnergyProForm';
import { Actions as DistributionPanelActions } from '../../actions/distribution_panel';

interface Props {
  onChange: (field: string, value: string | number) => void;
  onCreate: () => void;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  lockSiteId?: number;
  formErrorsVisible: boolean;
  displayFormErrors: (id: string) => void;
  apiError: ApiError;

  onEproChange: (field: string, value: string | number) => void;
  eproFields: EProEditableFields;
  eproIsLoading: boolean;
  eproFormErrors: GenericErrors;
  eproFormErrorsVisible: boolean;
  eproApiError: ApiError;
  displayEPFormErrors: (id: string) => void;
}

function NewDistributionPanelModal(props: Props) {
  const {
    fields,
    onChange,
    onClose,
    onCreate,
    isLoading,
    formErrors,
    lockSiteId,
    formErrorsVisible,
    displayFormErrors,
    apiError,

    // EnergyPro
    onEproChange,
    eproFields,
    eproFormErrors,
    eproFormErrorsVisible,
    eproApiError,
    displayEPFormErrors,

    isVisible,
  } = props;

  const dispatch = useDispatch();
  const [siteId, setSiteId] = React.useState(lockSiteId || -1);
  const [selectedTab, setSelectedTab] = React.useState(0);

  useEffect(() => {
    dispatch(createNewEnergyPro());
    setSelectedTab(0);
  }, [isVisible]);

  const onNext = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      setSelectedTab(1);
    }
  };

  const onDistributionPanelCreate = () => {
    if (hasKeys(eproFormErrors)) {
      displayEPFormErrors('new');
    } else {
      onCreate();
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button
        disabled={isLoading}
        onClick={selectedTab === 0 ? onNext : onDistributionPanelCreate}
      >
        {selectedTab === 0 ? 'Next' : 'Create'}
      </Button>
    </>
  );

  if (!isVisible) return null;
  return (
    <Modal actions={actions}>
      <ModalTitle>{CreateNewText.DISTRIBUTION_PANEL}</ModalTitle>
      <ModalContent>
        <Tabs>
          <Tab active={selectedTab === 0} onClick={() => setSelectedTab(0)}>
            Distribution Panel
          </Tab>
          <Tab
            active={selectedTab === 1}
            onClick={selectedTab === 0 ? onNext : () => setSelectedTab(1)}
          >
            EnergyPro
          </Tab>
        </Tabs>
        <br />
        {selectedTab === 0 && (
          <EditDistributionPanelForm
            fields={fields}
            onChange={onChange}
            lockSiteId={lockSiteId}
            formErrorsVisible={formErrorsVisible}
            formErrors={formErrors}
            getSiteId={setSiteId}
            apiError={{}}
          />
        )}
        {selectedTab === 1 && (
          <EditEnergyProForm
            isNew
            siteId={lockSiteId || siteId}
            fields={eproFields}
            onChange={onEproChange}
            formErrorsVisible={eproFormErrorsVisible}
            formErrors={eproFormErrors}
            distributionPanelEnergySource={fields.energySource}
          />
        )}
        {renderAPIerror(
          apiError,
          DistributionPanelActions.PATCH_DISTRIBUTION_PANEL_ERROR,
          DistributionPanelActions.CREATE_DISTRIBUTION_PANEL_ERROR
        )}
        {eproApiError && renderAPIerror(eproApiError)}
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({
  distributionPanels,
  energyPros,
}: ApplicationState) => {
  return {
    isVisible: distributionPanels.showNewDistributionPanelModal,
    ...distributionPanels.editById['new'],

    // EnergyPro
    eproFields: energyPros.editById['new']?.fields,
    eproIsLoading: energyPros.editById['new']?.isLoading,
    eproFormErrors: energyPros.editById['new']?.formErrors,
    eproFormErrorsVisible: energyPros.editById['new']?.formErrorsVisible,
    eproApiError: energyPros.editById['new']?.apiError,
  };
};

const mapDispatchToProps = {
  onClose: hideNewDistributionPanelModal,
  onChange: (field: string, value: string | number) =>
    updateField('new', field, value),
  onEproChange: (field: string, value: string | number) =>
    updateFieldEPro('new', field, value),
  onCreate: create,
  displayFormErrors,
  displayEPFormErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDistributionPanelModal);
