import {
  TimeDistance,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { useSubscribeToEntities } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import {
  formatIanaTimeZone,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { parseISO } from 'date-fns/esm';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import DeviceConnectionStatus from '../../components/DeviceConnectionStatus';
import { useIsStreamConnected } from '../../hooks/streamApi/useIsStreamConnected';
import { useAppLocale } from '../../hooks/useAppDetails';
import { ApplicationState } from '../../reducers';
import { DeviceStatusById } from '../../reducers/deviceStatus';
import { formatFullDateTime } from '../../utils/dates';
import {
  doTimestampsContainDefaults,
  pickOldestTimestamp,
  selectTimestampToUse,
} from '../../utils/devices';
import { MdLens } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';

// HACK, you can submit the uuid as the id and this will still work,

export enum DisplayType {
  TIME_ONLY = 'TIME_ONLY',
  TIME_DISTANCE_ONLY = 'TIME_DISTANCE_ONLY',
  STATUS_ONLY_WITH_TEXT = 'STATUS_ONLY_WITH_TEXT',
  STATUS_ONLY_WITHOUT_TEXT = 'STATUS_ONLY_WITHOUT_TEXT',
  STATUS_ONLY_WITH_TEXT_COLOR = 'STATUS_ONLY_WITH_TEXT_COLOR',
  COMBINED = 'COMBINED',
}

type Props = {
  devices: EntityInfoToSub[];
  displayType?: DisplayType;
  additionalOnlineState?: boolean;
  ianaTimeZoneCode?: string;
  offlineReason?: string | null;
};

const DeviceOnlineState: React.FunctionComponent<Props> = ({
  devices,
  displayType,
  // default this to true because if nothing is specified it should have no effect
  additionalOnlineState = true,
  ianaTimeZoneCode,
  offlineReason,
}) => {
  const locale = useAppLocale();
  const dispatch = useDispatch();
  const streamConnected = useIsStreamConnected();
  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);

  const deviceStatuses = isDefined(deviceStatusById)
    ? devices.map(({ id }) => deviceStatusById[id]).filter(isDefined)
    : [];
  const onlineStates = deviceStatuses.map(
    deviceState => deviceState.onlineState
  );
  const onlineStatesUpdatedAt = deviceStatuses.map(
    ({ timestamp, onlineStateUpdatedAt, onlineState }) => ({
      timestamp,
      onlineStateUpdatedAt,
      onlineState,
    })
  );
  const areAllSubbedDevicesOnline: boolean = onlineStates.every(
    onlineState => onlineState
  );
  const isDeviceOnline: boolean =
    additionalOnlineState && areAllSubbedDevicesOnline;

  const subscribeToStatus = (
    vendor: string,
    uuid: string,
    id: string | number
  ) => dispatch(subscribeToDeviceStatus(vendor, uuid, id));
  const unsubscribeFromStatus = (
    vendor: string,
    uuid: string,
    id: string | number
  ) => dispatch(unsubscribeFromDeviceStatus(vendor, uuid, id));
  useSubscribeToEntities(
    devices,
    subscribeToStatus,
    unsubscribeFromStatus,
    streamConnected
  );

  if (
    deviceStatuses.length === 0 ||
    doTimestampsContainDefaults(onlineStatesUpdatedAt)
  ) {
    return <div style={{ paddingTop: '0.5rem' }}>{global.NOT_AVAILABLE}</div>;
  }

  // the timestamp is maybe now inaccurate if additionalOnlineState is used?
  const timestamps = onlineStatesUpdatedAt.map(state =>
    selectTimestampToUse(state)
  );
  const timestampToUse = pickOldestTimestamp(timestamps);

  if (!isDefined(timestampToUse)) {
    return <div style={{ paddingTop: '0.5rem' }}>{global.NOT_AVAILABLE}</div>;
  }

  switch (displayType) {
    case DisplayType.TIME_ONLY: {
      const display = ianaTimeZoneCode ? (
        <>
          {formatIanaTimeZone(
            parseISO(timestampToUse),
            locale.dateTimeFormat,
            ianaTimeZoneCode,
            true
          )}
        </>
      ) : (
        <>{formatFullDateTime(timestampToUse, locale.fullDateTimeFormat)}</>
      );

      return display;
    }

    case DisplayType.TIME_DISTANCE_ONLY: {
      const display = <TimeDistance timestamp={timestampToUse} />;
      if (ianaTimeZoneCode) {
        return (
          <Tooltip
            content={formatIanaTimeZone(
              parseISO(timestampToUse),
              locale.dateTimeFormat,
              ianaTimeZoneCode,
              true
            )}
            arrowDirection="bottom"
          >
            {display}
          </Tooltip>
        );
      }
      return display;
    }

    case DisplayType.STATUS_ONLY_WITH_TEXT: {
      const display = (
        <DeviceConnectionStatus connectionStatus={isDeviceOnline} />
      );

      if (ianaTimeZoneCode) {
        return (
          <Tooltip
            content={formatIanaTimeZone(
              parseISO(timestampToUse),
              locale.dateTimeFormat,
              ianaTimeZoneCode,
              true
            )}
            arrowDirection="bottom"
          >
            {display}
          </Tooltip>
        );
      }
      return display;
    }

    case DisplayType.STATUS_ONLY_WITHOUT_TEXT:
      return (
        <DeviceConnectionStatus connectionStatus={isDeviceOnline} hideText />
      );
    case DisplayType.STATUS_ONLY_WITH_TEXT_COLOR:
      return (
        <>
          <div>
            <strong>
              {isDeviceOnline ? (
                <span style={{ color: 'var(--green-base)' }}>
                  <MdLens size="10" style={{ marginRight: '4px' }} />
                  Online
                </span>
              ) : (
                <span style={{ color: 'var(--pink-base)' }}>
                  <MdLens size="10" style={{ marginRight: '4px' }} />
                  Offline
                </span>
              )}
            </strong>
          </div>
        </>
      );
    default: {
      const display = (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div>
              <strong>
                {isDeviceOnline ? (
                  <span style={{ color: 'var(--green-base)' }}>Online</span>
                ) : (
                  <span style={{ color: 'var(--pink-base)' }}>Offline</span>
                )}
              </strong>
            </div>
            <TimeDistance timestamp={timestampToUse} />
          </div>

          {/* Should not determine the visibility of offlineReason by
              isDeviceOnline because isDeviceOnline is not accurate. In
              practice, the Offline status will only appear after the device
              is actually offline for 5-6 mins (According to Daniel Tsang). So
              just display offlineReason whenever it's there. The offlineReason
              will remain unchanged for 5 mins, after that iam will reset it to
              upon receiving the first online message from data processor */}
          {!!offlineReason && (
            <Tooltip
              underline={false}
              arrowDirection="top"
              content={
                <>
                  <b>Root Cause</b>
                  <div style={{ color: 'var(--ambient-basePlus75)' }}>
                    {offlineReason}
                  </div>
                </>
              }
            >
              <IoIosInformationCircleOutline
                color="var(--accent-base)"
                size={20}
              />
            </Tooltip>
          )}
        </div>
      );

      if (ianaTimeZoneCode) {
        return (
          <Tooltip
            content={formatIanaTimeZone(
              parseISO(timestampToUse),
              locale.dateTimeFormat,
              ianaTimeZoneCode,
              true
            )}
            arrowDirection="bottom"
          >
            {display}
          </Tooltip>
        );
      }
      return display;
    }
  }
};

export default DeviceOnlineState;
