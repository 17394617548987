import React from 'react';
import { Link } from 'react-router-dom';
import {
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionStatus,
  ThermostatWorkingMode,
  ThermostatWorkingModeLabel,
  InspectionJumpToRef,
} from '@energybox/react-ui-library/dist/types';
import { ThermostatIcon } from '@energybox/react-ui-library/dist/icons';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import InspectionTile, { InspectionHeader } from '../InspectionTile';
import { getSensorsTableColumns } from '../SensorsTile';
import { useCurrentUser } from '../../../../hooks/useAppDetails';
import {
  getId,
  getTitle,
  getDetailFields,
  getSummaryFields,
  getSensorsOrActuatorsSummaryFields,
  doesItemContainErrorOrWarning,
  transformTemperatureValue,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { Routes } from '../../../../routes';

type Props = {
  siteId: string;
  data: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  isEBThermostat?: boolean;
  isHeatPump?: boolean;
  jumpToRef: InspectionJumpToRef;
};

const ThermostatTile: React.FC<Props> = ({
  siteId,
  data,
  detailLevel,
  isEBThermostat,
  isHeatPump,
  jumpToRef,
}) => {
  const currentUser = useCurrentUser();
  if (!currentUser) return null;
  const detailsPageLink = `${Routes.DEVICES}${Routes.GATEWAYS}/${getId(data)}`;
  const equipmentId = (data.equipment_id as InspectionDataField)
    ?.field as string;
  const mappedEquipmentPageLink = `${Routes.EQUIPMENT}/${equipmentId}`;
  const mappedEquipmentEditorLink = `${mappedEquipmentPageLink}${Routes.HVAC_EDITOR}`;
  const mappedEquipmentHvacCardLink = `${mappedEquipmentPageLink}${Routes.HVAC_CONTROLS_CARD}`;

  const getSensorsCount = detectedSensorNum => {
    const configuredSensorNum = (data.number_of_sensors_configured as InspectionDataField)
      ?.field as number;
    return `${
      isDefined(detectedSensorNum) ? detectedSensorNum : global.NOT_AVAILABLE
    } detected / ${
      isDefined(configuredSensorNum)
        ? configuredSensorNum
        : global.NOT_AVAILABLE
    } configured`;
  };

  const transformSetPoints = coolingValue => {
    const heatingValue = (data.thermostat_heating_setpoint as InspectionDataField)
      ?.field as string;
    const coolingSetPoint = transformTemperatureValue(
      coolingValue,
      currentUser
    );
    const heatingSetPoint = transformTemperatureValue(
      heatingValue,
      currentUser
    );
    const thermostatMode = (data.thermostat_mode as InspectionDataField)
      ?.field as string;
    if (thermostatMode === ThermostatWorkingMode.HEATING) {
      return heatingSetPoint;
    } else if (thermostatMode === ThermostatWorkingMode.COOLING) {
      return coolingSetPoint;
    } else if (thermostatMode === ThermostatWorkingMode.AUTO) {
      return isEBThermostat
        ? `${heatingSetPoint} - ${coolingSetPoint}`
        : `${coolingSetPoint} - ${heatingSetPoint}`;
    }
    return global.NOT_AVAILABLE;
  };

  const renderHumiditySetpoint = humidifySetpoint => {
    const dehumidifySetpoint = (data.dehumidify_setpoint as InspectionDataField)
      ?.field as number;
    const humidityMode = (data.humidity_mode as InspectionDataField)
      ?.field as string;

    if (humidityMode === 'AUTO') {
      return `${humidifySetpoint}% - ${dehumidifySetpoint}%`;
    } else if (humidityMode === 'HUMIDIFY') {
      return `${humidifySetpoint}%`;
    } else if (humidityMode === 'DEHUMIDIFY') {
      return `${dehumidifySetpoint}%`;
    } else {
      return global.NOT_AVAILABLE;
    }
  };

  function toTitleCase(str) {
    if (!isDefined(str) || str == null) {
      return global.NOT_AVAILABLE;
    }
    return str
      .toLowerCase()
      .split(' ')
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  const fields = [
    ...(!isEBThermostat
      ? [
          {
            name: 'IP Address',
            key: 'ip',
          },
          {
            name: 'Tstat Onboard Temp',
            key: 'onboard_temperature',
            transformValue: value =>
              transformTemperatureValue(value, currentUser),
          },
        ]
      : []),
    {
      name: 'MAC Address (UUID)',
      key: 'uuid',
      link: detailsPageLink,
    },
    {
      name: 'Control Temperature',
      key: 'control_temperature',
      transformValue: value => transformTemperatureValue(value, currentUser),
    },
    {
      name: 'Thermostat Model',
      key: 'thermostat_model',
    },
    {
      name: 'Thermostat Set Point',
      key: 'thermostat_cooling_setpoint',
      link: !!equipmentId ? mappedEquipmentEditorLink : undefined,
      transformValue: value => transformSetPoints(value),
    },
    {
      name: 'Configured HVAC Type',
      key: 'HVAC_type',
    },
    {
      name: 'Thermostat Mode',
      key: 'thermostat_mode',
      link: !!equipmentId ? mappedEquipmentEditorLink : undefined,
      transformValue: value =>
        ThermostatWorkingModeLabel[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: isEBThermostat
        ? isHeatPump === null || isHeatPump === true
          ? 'Configured Reversing Valve'
          : 'Configured Heating System'
        : 'Configured Reversing Valve',
      key: isEBThermostat
        ? isHeatPump === null || isHeatPump === true
          ? 'reversing_valve'
          : 'system_type'
        : 'reversing_valve',
    },
    {
      name: isEBThermostat ? 'Humidity Setpoint' : 'Control Mode',
      key: isEBThermostat ? 'humidity_setpoint' : 'control_mode',
      link:
        !!equipmentId && !isEBThermostat
          ? mappedEquipmentHvacCardLink
          : undefined,
      transformValue: value =>
        !isEBThermostat ? value : renderHumiditySetpoint(value),
    },
    ...(!isEBThermostat
      ? [
          {
            name: 'Number of Sensors',
            key: 'number_of_sensors_detected',
            transformValue: value => getSensorsCount(value),
          },
        ]
      : [
          {
            name: 'Control Source',
            key: 'thermostat_sensor_source',
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Humidity Mode',
            key: 'humidity_mode',
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Fallback Setting',
            key: 'thermostat_sensor_fallback',
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Control Mode',
            key: 'control_mode',
            link: !!equipmentId ? mappedEquipmentHvacCardLink : undefined,
            transformValue: value => toTitleCase(value as String),
          },
          {
            name: 'Fallback Status',
            key: 'thermostat_sensor_status',
            transformValue: value => value || global.NOT_AVAILABLE,
          },
        ]),
    {
      name: 'Mapped Equipment',
      key: 'equipment_title',
      link: !!equipmentId ? mappedEquipmentPageLink : undefined,
    },
    ...(isEBThermostat
      ? [
          {
            name: 'Signal Strength',
            key: 'signal_strength',
            transformValue: value =>
              value ? `${value} dBm` : global.NOT_AVAILABLE,
          },
          {
            name: 'Display Name',
            key: 'thermostat_display_name',
            transformVale: value => value || global.NOT_AVAILABLE,
          },
        ]
      : []),
  ];

  const subtitle = getTitle(data);
  const thermostatSummaryFields = getSummaryFields(data, detailsPageLink);
  const sensorList = ((data.sensor_list ||
    []) as InspectionDataFieldsByKey[]).map(sensor => ({
    ...sensor,
    type: {
      field: ['TEMPERATURE'],
      error: null,
      warning: null,
    },
    vendor: {
      field: 'venstar',
      error: null,
      warning: null,
    },
    status: {
      field: 'online',
      error: null,
      warning: null,
    },
  }));
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(sensorList);
  const sensorsColumns = getSensorsTableColumns({
    data: sensorList,
    detailLevel,
    siteId,
    currentUser,
    showColumns: ['Type', 'Sensor Name', 'Status', 'Last Reading', 'Power'],
    noLastCheckInTime: true,
    showTempPlusHumidity: true,
  });
  const thermostatTileTitle = isEBThermostat
    ? InspectionComponentName.EB_THERMOSTAT
    : InspectionComponentName.THERMOSTAT;

  const externalSensorsTitle = isEBThermostat
    ? InspectionComponentName.EXTERNAL_WIRELESS_SENSORS
    : InspectionComponentName.EXTERNAL_SENSORS;

  const tableData =
    sensorList.filter(
      item =>
        item &&
        (detailLevel === InspectionDetailLevel.ALL ||
          doesItemContainErrorOrWarning(item))
    ) || [];
  const shouldHideTable =
    detailLevel === InspectionDetailLevel.ISSUES && !tableData.length;
  if (
    detailLevel === InspectionDetailLevel.ISSUES &&
    thermostatSummaryFields.status === InspectionStatus.GOOD &&
    (!sensorsSummaryFields.status ||
      sensorsSummaryFields.status === InspectionStatus.GOOD)
  ) {
    return null;
  }
  return (
    <InspectionTile
      title={thermostatTileTitle}
      titleIcon={<ThermostatIcon variant="small" size="20" />}
      subtitle={
        !!subtitle && (
          <Link to={detailsPageLink} target="_blank">
            {subtitle}
          </Link>
        )
      }
      summaryFields={thermostatSummaryFields}
      detailFields={getDetailFields(fields, data, detailLevel)}
      detailTableHeader={
        !shouldHideTable && (
          <InspectionHeader
            title={externalSensorsTitle}
            summaryFields={sensorsSummaryFields}
          />
        )
      }
      showDetailTableHeaderWhenCollapsed
      detailTable={
        shouldHideTable
          ? undefined
          : {
              dataIsLoading: false,
              columns: sensorsColumns,
              data: tableData,
            }
      }
      noTableDataMessage="No Sensors Configured"
      jumpToRef={jumpToRef}
    />
  );
};

export default ThermostatTile;
