import {
  FormText,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import {
  Equipment,
  ThermostatLabel,
} from '@energybox/react-ui-library/dist/types';
import { HvacType } from '@energybox/react-ui-library/dist/types/Gateway';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SelectNetworkGroup from '../../containers/Selects/SelectNetworkGroup';
import SelectSite from '../../containers/Selects/SelectSite';
import SelectSpace from '../../containers/Selects/SelectSpace';
import { useGetGatewaysByNetworkGroupById } from '../../hooks/useGetGateways';
import { Routes } from '../../routes';
import { EditThermostat, ThermostatFormErrors } from '../../types/Thermostat';
import ModalFormContent from '../ModalFormContent';
import styles from './EditThermostatForm.module.css';
import { ApplicationState } from '../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { getThermostatDetailsByUuid } from '../../actions/thermostats';
import useGetNetworkGroupById from '../../hooks/useGetNetworkGroup';

type Props = {
  lockSiteId?: number;
  onChange: (field: string, value: string | number) => void;
  onSelectSite: (value: number) => void;
  fields: EditThermostat;
  formErrorsVisible: boolean;
  formErrors: ThermostatFormErrors;
  equipment?: Equipment;
  isNew?: boolean;
  isEbThermostat?: boolean;
  thermostatModel?: string | null;
};

const EditThermostatForm: React.FC<Props> = ({
  lockSiteId,
  onChange,
  onSelectSite,
  fields,
  formErrorsVisible,
  formErrors,
  equipment,
  isNew = false,
  isEbThermostat,
  thermostatModel,
}) => {
  const {
    title,
    description,
    uuid,
    siteId,
    spaceId,
    networkGroupId,
    wirelessTemperatureSensorsCount,
  } = fields;
  const dispatch = useDispatch();
  const [formattedUuid, setFormattedUuid] = React.useState<string>(uuid);

  const isVenstarEditView = !isNew && !isEbThermostat;
  const isEnergyboxEditView = !isNew && isEbThermostat;

  const gateways = useGetGatewaysByNetworkGroupById(networkGroupId);

  const gateway = gateways?.find(gateway => {
    return gateway?.title === title;
  });
  const hvacType = gateway?.hvacType;

  const reversingValve = gateway?.reversingValve;

  const isHeatPump = gateway?.hvacType === HvacType.HEAT_PUMP;

  const { networkGroup } = useGetNetworkGroupById(
    networkGroupId >= 0 ? networkGroupId : undefined
  );

  const thermostatDetail = useSelector((state: ApplicationState) => {
    return state.thermostats.thermostatDetailById[formattedUuid];
  });

  const displayName = thermostatDetail?.configuration?.equipmentName;

  const onChangeUuid = value => {
    const formatted =
      value
        .replace(/[^0-9A-Fa-f]/g, '')
        ?.slice(0, 12)
        ?.toUpperCase()
        ?.match(/.{1,2}/g)
        ?.join(':') || '';
    setFormattedUuid(formatted);
    onChange('uuid', formatted);
  };

  React.useEffect(() => {
    if (formattedUuid.length === 17 && networkGroup?.edge?.serialNumber) {
      dispatch(
        getThermostatDetailsByUuid(
          networkGroup.edge.serialNumber,
          siteId,
          formattedUuid
        )
      );
    }
  }, [formattedUuid, networkGroup?.edge?.serialNumber]);

  React.useEffect(() => {
    setFormattedUuid(uuid);
  }, [uuid]);

  return (
    <>
      {
        <div>
          <div className={styles.flexContainer}>
            <div style={{ width: isEnergyboxEditView ? '50%' : '100%' }}>
              <ModalFormContent>
                <div>
                  <Label required>{ThermostatLabel.DEVICE_NAME}</Label>
                </div>
                <div>
                  <InputField
                    type="text"
                    name="title"
                    autoComplete="title"
                    value={title}
                    onChange={e => onChange('title', e.currentTarget.value)}
                    error={formErrorsVisible && !!formErrors.title}
                    customErrorText={formErrors.title}
                  />
                </div>
                <div>
                  <Label>{ThermostatLabel.DESCRIPTION}</Label>
                </div>
                <div>
                  <InputField
                    type="text"
                    name="description"
                    value={description}
                    autoComplete="description"
                    onChange={e =>
                      onChange('description', e.currentTarget.value)
                    }
                  />
                </div>

                <div>
                  <Label required>{ThermostatLabel.UUID}</Label>
                </div>
                <div className={styles.uuidContainer}>
                  <InputField
                    type="text"
                    name="uuid"
                    value={formattedUuid}
                    autoComplete="uuid"
                    onChange={e => onChangeUuid(e.currentTarget.value)}
                    error={formErrorsVisible && !!formErrors.uuid}
                    customErrorText={formErrors.uuid}
                  />
                  {isDefined(displayName) && (
                    <span className={styles.displayName}>
                      {displayName || global.NOT_AVAILABLE}
                    </span>
                  )}
                </div>

                {!isNew && (
                  <>
                    <div>
                      <Label>Thermostat Model</Label>
                    </div>
                    <div className={styles.thermostatFormLabel}>
                      {thermostatModel || global.NOT_AVAILABLE}
                    </div>
                  </>
                )}
                {isVenstarEditView && (
                  <>
                    <div>
                      <Label>HVAC Type</Label>
                    </div>
                    <div className={styles.thermostatFormLabel}>{hvacType}</div>
                    <>
                      {!isNew && isHeatPump && (
                        <>
                          <div>
                            <Label>Reversing Valve (Heatpump-only)</Label>
                          </div>
                          <div className={styles.thermostatFormLabel}>
                            {isHeatPump ? reversingValve : global.NOT_AVAILABLE}
                          </div>
                        </>
                      )}
                    </>
                  </>
                )}
                {((isNew && !isEbThermostat) || isVenstarEditView) && (
                  <>
                    <div>
                      <Label required>Remote Sensors</Label>
                    </div>
                    <div>
                      <InputField
                        type="number"
                        name="wirelessTemperatureSensorsCount"
                        autoComplete="wirelessTemperatureSensorsCount"
                        value={wirelessTemperatureSensorsCount}
                        onChange={e =>
                          onChange(
                            'wirelessTemperatureSensorsCount',
                            Number.parseInt(e.currentTarget.value)
                          )
                        }
                        error={
                          formErrorsVisible &&
                          !!formErrors.wirelessTemperatureSensorsCount
                        }
                        customErrorText={
                          formErrors.wirelessTemperatureSensorsCount
                        }
                      />
                    </div>
                  </>
                )}
                {!isEnergyboxEditView && (
                  <>
                    {
                      <>
                        <div>
                          <Label required>{ThermostatLabel.SITE}</Label>
                        </div>
                        <div>
                          <SelectSite
                            disabled={!!lockSiteId}
                            onSelect={onSelectSite}
                            value={siteId}
                            error={formErrorsVisible && !!formErrors.siteId}
                            customErrorText={formErrors.siteId}
                          />
                        </div>
                        <div>
                          <Label required>{ThermostatLabel.LOCATION}</Label>
                        </div>
                        <div>
                          <SelectSpace
                            onSelect={value => onChange('spaceId', value)}
                            value={spaceId}
                            siteId={siteId}
                            error={formErrorsVisible && !!formErrors.spaceId}
                            customErrorText={formErrors.spaceId}
                          />
                        </div>
                        <div>
                          <Label required>
                            {ThermostatLabel.NETWORK_GROUP}
                          </Label>
                        </div>
                        <div>
                          <SelectNetworkGroup
                            siteId={siteId}
                            onSelect={value =>
                              onChange('networkGroupId', value)
                            }
                            value={networkGroupId}
                            error={
                              formErrorsVisible && !!formErrors.networkGroupId
                            }
                            customErrorText={formErrors.networkGroupId}
                          />
                        </div>
                        {!isNew && (
                          <>
                            <div>
                              <Label>Mapped Equipment</Label>
                            </div>
                            <div className={styles.equipmentLabel}>
                              {isDefined(equipment) ? (
                                <Link
                                  to={`${Routes.EQUIPMENT}/${equipment.id}`}
                                >
                                  {equipment.title || global.NOT_AVAILABLE}
                                </Link>
                              ) : (
                                global.NOT_AVAILABLE
                              )}
                            </div>
                          </>
                        )}
                      </>
                    }
                  </>
                )}
              </ModalFormContent>
              {(isNew || isVenstarEditView) && (
                <FormText>
                  <div>* Mandatory fields</div>
                  <div
                    style={{
                      color: 'var(--ambient-basePlus50)',
                      fontStyle: 'italic',
                    }}
                  >
                    Please check for compatible models with your Energybox
                    representative
                  </div>
                </FormText>
              )}
            </div>

            {isEnergyboxEditView && (
              <div className={styles.rightContainer}>
                <ModalFormContent>
                  {
                    <>
                      <div>
                        <Label required>{ThermostatLabel.SITE}</Label>
                      </div>
                      <div>
                        <SelectSite
                          disabled={!!lockSiteId}
                          onSelect={onSelectSite}
                          value={siteId}
                          error={formErrorsVisible && !!formErrors.siteId}
                          customErrorText={formErrors.siteId}
                        />
                      </div>
                      <div>
                        <Label required>{ThermostatLabel.LOCATION}</Label>
                      </div>
                      <div>
                        <SelectSpace
                          onSelect={value => onChange('spaceId', value)}
                          value={spaceId}
                          siteId={siteId}
                          error={formErrorsVisible && !!formErrors.spaceId}
                          customErrorText={formErrors.spaceId}
                        />
                      </div>
                      <div>
                        <Label required>{ThermostatLabel.NETWORK_GROUP}</Label>
                      </div>
                      <div>
                        <SelectNetworkGroup
                          siteId={siteId}
                          onSelect={value => onChange('networkGroupId', value)}
                          value={networkGroupId}
                          error={
                            formErrorsVisible && !!formErrors.networkGroupId
                          }
                          customErrorText={formErrors.networkGroupId}
                        />
                      </div>
                      <div>
                        <Label>Mapped Equipment</Label>
                      </div>
                      <div className={styles.equipmentLabel}>
                        {isDefined(equipment) ? (
                          <Link to={`${Routes.EQUIPMENT}/${equipment.id}`}>
                            {equipment.title || global.NOT_AVAILABLE}
                          </Link>
                        ) : (
                          global.NOT_AVAILABLE
                        )}
                      </div>
                    </>
                  }
                </ModalFormContent>
              </div>
            )}
          </div>
          <div></div>
        </div>
      }
    </>
  );
};

export default EditThermostatForm;
