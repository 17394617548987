import {
  InputField,
  Label,
  SingleDatePicker,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';

import React from 'react';
import SelectEquipmentGroup from '../../containers/Selects/SelectEquipmentGroup';
import SelectEquipmentType from '../../containers/Selects/SelectEquipmentType';
import SelectSite from '../../containers/Selects/SelectSite';
import SelectSpace from '../../containers/Selects/SelectSpace';
import { PropertyToLabel } from '../../types/global';
import styles from './EditEquipmentForm.module.css';

import { DateTime } from 'luxon';
import { useAppLocale } from '../../hooks/useAppDetails';
import { useGetSite } from '../../hooks/useSites';
import {
  checkCommonPlural,
  determineShouldShowSwitchLink,
} from '@energybox/react-ui-library/dist/utils';
import useGetUserPermissions from '../../hooks/useGetUserPermissions';
import { NewDashboard } from '@energybox/react-ui-library/dist/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';

interface Props {
  onChange: (field: string, value: any) => void;
  onEquipmentGroupChange: (groupId: number) => void;
  equipmentId?: number;
  siteId?: number;
  model: string;
  vendor: string;
  typeId: number;
  groupId: number;
  title: string;
  serialNumber: string;
  manufactureDate: string;
  description: string;
  spaceId: number;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
}

const EditEquipmentForm = ({
  onEquipmentGroupChange,
  onChange,
  equipmentId,
  siteId,
  title,
  spaceId,
  typeId,
  groupId,
  description,
  model,
  vendor,
  formErrorsVisible,
  serialNumber,
  manufactureDate,
  formErrors = {},
}: Props) => {
  const site = useGetSite(siteId);
  const locale = useAppLocale();

  const userPermission = useGetUserPermissions();
  const shouldShowSwitchLink = determineShouldShowSwitchLink(userPermission);

  const manufactureDT = manufactureDate
    ? DateTime.fromISO(manufactureDate)
    : undefined;
  const displayDate = manufactureDT
    ? manufactureDT.toFormat('yyyy-MM-dd')
    : undefined;

  const ageOfEquipmentString = manufactureDT
    ? getAgeOfEquipment(manufactureDT)
    : undefined;

  const accessToken = useSelector(({ app }: ApplicationState) => {
    return app.accessToken;
  });

  return (
    <div className={styles.form}>
      <div>
        <div className={styles.inputGroup}>
          <div>
            <Label required htmlFor="equipmentName">
              Equipment Name
            </Label>
          </div>
          <div>
            <InputField
              id="equipmentName"
              type="text"
              name="title"
              autoComplete="title"
              value={title}
              onChange={({ currentTarget }) =>
                onChange('title', currentTarget.value)
              }
              error={formErrorsVisible && !!formErrors.title}
              customErrorText={formErrors.title && formErrors.title[0]}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label htmlFor="description">{PropertyToLabel.description}</Label>
          <div>
            <InputField
              type="text"
              id="description"
              name="description"
              autoComplete="description"
              value={description}
              onChange={({ currentTarget }) =>
                onChange('description', currentTarget.value)
              }
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <Label required>{PropertyToLabel.siteId}</Label>
          <div>
            {siteId && (
              <SelectSite
                disabled
                value={siteId}
                onSelect={id => onChange('spaceId', id)}
              />
            )}
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label required>{PropertyToLabel.spaceId}</Label>
          <div>
            {siteId && (
              <SelectSpace
                siteId={siteId}
                value={spaceId}
                onSelect={id => onChange('spaceId', id)}
                error={formErrorsVisible && !!formErrors.spaceId}
                customErrorText={formErrors.spaceId && formErrors.spaceId[0]}
              />
            )}
          </div>
        </div>
        <div className={styles.inputGroup}>
          <Label>* required fields</Label>
        </div>
      </div>

      <div>
        <div className={styles.inputGroup}>
          <div>
            <Label required>{PropertyToLabel.groupId}</Label>
          </div>
          <div>
            <SelectEquipmentGroup
              value={groupId}
              onSelect={id => {
                onEquipmentGroupChange(id);
              }}
              error={formErrorsVisible && !!formErrors.groupId}
              customErrorText={formErrors.groupId && formErrors.groupId[0]}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div>
            <Label required>{PropertyToLabel.typeId}</Label>
          </div>
          <div>
            <SelectEquipmentType
              groupId={groupId}
              value={typeId}
              onSelect={id => onChange('typeId', id)}
              error={formErrorsVisible && !!formErrors.typeId}
              customErrorText={formErrors.typeId && formErrors.typeId[0]}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div>
            <Label>{PropertyToLabel.vendor}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="vendor"
              autoComplete="vendor"
              value={vendor}
              onChange={({ currentTarget }) =>
                onChange('vendor', currentTarget.value)
              }
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div>
            <Label>{PropertyToLabel.model}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="model"
              autoComplete="model"
              value={model}
              onChange={({ currentTarget }) =>
                onChange('model', currentTarget.value)
              }
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div>
            <Label>{PropertyToLabel.serialNumber}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="serialNumber"
              autoComplete="serial number"
              value={serialNumber}
              onChange={({ currentTarget }) =>
                onChange('serialNumber', currentTarget.value)
              }
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div>
            <Label>{PropertyToLabel.manufactureDate}</Label>
          </div>
          <div>
            <SingleDatePicker
              value={displayDate}
              setDate={(date: string) => {
                onChange(
                  'manufactureDate',
                  DateTime.fromFormat(date, 'yyyy-MM-dd', {
                    zone: site?.timeZone,
                  }).toISO()
                );
              }}
              locale={locale}
              ianaTimeZoneCode={site?.timeZone}
            />
            {ageOfEquipmentString && <Label>{ageOfEquipmentString}</Label>}
          </div>
        </div>
      </div>
      {shouldShowSwitchLink && (
        <span className={styles.imageLink}>
          <a
            href={`${process.env.REACT_APP_PLATFORM_SWITCH_URL}/sites/${siteId}/equipment/${equipmentId}#accessToken=${accessToken}`}
            target="_blank"
            className={styles.linkText}
          >
            {' '}
            <Tooltip
              content={'Link to Dashboard'}
              tooltipTextClassName={styles.tooltip}
              arrowDirection="top"
              underline={false}
            >
              <NewDashboard size={36} />
            </Tooltip>
          </a>
        </span>
      )}
    </div>
  );
};

const getAgeOfEquipment = (dateTime: DateTime) => {
  const ageOfEquipmentDuration = dateTime
    .diffNow(['years', 'months'])
    .toObject();

  const ageOfEquipmentString = `${checkCommonPlural(
    'year',
    Math.floor(Math.abs(ageOfEquipmentDuration.years || 0))
  )} and ${checkCommonPlural(
    'month',
    Math.floor(Math.abs(ageOfEquipmentDuration.months || 0))
  )}`;

  return ageOfEquipmentString;
};

export default EditEquipmentForm;
