import React from 'react';
import { Link } from 'react-router-dom';
import { parseISO } from 'date-fns';
import pathOr from 'ramda/src/pathOr';
import {
  ExtraShortSkeletonCell,
  MediaElement,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
} from '@energybox/react-ui-library/dist/components';
import {
  CurrentUser,
  InspectionComponentName,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionOnlineStatus,
  InspectionStatus,
  OpacityIndex,
  ResourceType,
  SensorType,
  SortDirection,
  InspectionJumpToRef,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import InspectionTile, {
  getErrorOrWarningIconForField,
} from '../InspectionTile';
import TimestampTooltip from '../TimestampTooltip';
import {
  doesItemContainErrorOrWarning,
  getSensorsOrActuatorsSummaryFields,
  transformTemperatureValue,
  transformDoorValue,
  getSensorTypes,
  getRelativeTime,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { SensorTypeIconWithHoverText } from '../../../../utils/sensor';
import { Routes } from '../../../../routes';
import { useCurrentUser } from '../../../../hooks/useAppDetails';

import tileStyles from '../InspectionTile/InspectionTile.module.css';
import { ThermostatIcon } from '@energybox/react-ui-library/dist/icons';

type Props = {
  siteId: string;
  data: InspectionDataFieldsByKey[];
  statisticsData?: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  jumpToRef: InspectionJumpToRef;
};

type SensorTableColumnsProps = {
  data: InspectionDataFieldsByKey[];
  detailLevel: InspectionDetailLevel;
  siteId: string;
  linkToPairedSensorActions?: string;
  currentUser: CurrentUser;
  showColumns?: string[];
  noLastCheckInTime?: boolean;
  isWhitelistSensorNull?: (uuid: string) => boolean;
  isWhiteListTab?: boolean;
  displayNewSensorModal?: (siteId?: string, uuid?: string) => void;
  showTempPlusHumidity?: boolean;
};

export const getSensorsTableColumns = (props: SensorTableColumnsProps) => {
  const {
    siteId,
    linkToPairedSensorActions,
    showColumns = [],
    noLastCheckInTime = false,
    isWhitelistSensorNull,
    isWhiteListTab = false,
    currentUser,
    showTempPlusHumidity = false,
  } = props;

  const columns = [
    {
      header: 'Type',
      width: '5%',
      defaultSortDirection: SortDirection.ASC,
      isDefaultSort: false,
      cellContent: ({ type }) => {
        return (
          <MediaElement
            key={type?.field?.toString() || ''}
            icon={
              <>
                {type?.field?.map(sensorType => (
                  <SensorTypeIconWithHoverText
                    key={sensorType}
                    sensorType={sensorType}
                  />
                )) || null}
                {getErrorOrWarningIconForField(type, 'topLeft')}
              </>
            }
            title={''}
          />
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        const getTypes = (s: InspectionDataFieldsByKey) => {
          const types = pathOr([], ['type', 'field'], s);
          return types.sort().join();
        };
        let sort = genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getTypes
        );
        const uuid = (b.UUID as InspectionDataFieldsByKey)?.field as string;
        return isWhitelistSensorNull && isWhitelistSensorNull(uuid) ? 1 : sort;
      },
    },
    {
      width: '17.5%',
      header: 'Sensor Name',
      cellContent: ({ id, title, UUID }) => {
        const sensorTitle = title?.field || ResourceType.SENSOR;
        return (
          <>
            <MediaElement
              key={title?.field}
              title={
                !!id ? (
                  !isWhiteListTab ||
                  (isWhitelistSensorNull &&
                    isWhitelistSensorNull(UUID?.field)) ? (
                    <Link
                      to={`${Routes.DEVICES}${Routes.SENSORS}/${id?.field}`}
                      target="_blank"
                    >
                      {sensorTitle}
                    </Link>
                  ) : (
                    sensorTitle
                  )
                ) : (
                  sensorTitle
                )
              }
            />
            {getErrorOrWarningIconForField(title)}
          </>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
          'field',
        ]);
      },
    },
    {
      width: '10%',
      header: noLastCheckInTime ? 'Status' : 'Status / Last Check-In',
      cellContent: ({ id, status, last_check_in, UUID }) => {
        const statusText = InspectionOnlineStatus[status?.field as string];
        return (
          <div>
            {!isWhiteListTab ||
            (isWhitelistSensorNull && isWhitelistSensorNull(UUID?.field)) ? (
              <Link
                className={
                  tileStyles[(status?.field as string)?.toLowerCase() || '']
                }
                to={`${Routes.DEVICES}${Routes.SENSORS}/${id?.field}`}
                target="_blank"
              >
                {statusText === 'Active'
                  ? 'Online'
                  : statusText === 'Inactive'
                  ? 'Offline'
                  : statusText}
                {getErrorOrWarningIconForField(status)}
              </Link>
            ) : (
              <div
                className={
                  tileStyles[(status?.field as string)?.toLowerCase() || '']
                }
              >
                {statusText === 'Active'
                  ? 'Online'
                  : statusText === 'Inactive'
                  ? 'Offline'
                  : statusText}
                {getErrorOrWarningIconForField(status)}
              </div>
            )}
            {!noLastCheckInTime && (
              <div>{getRelativeTime(parseISO(last_check_in?.field))}</div>
            )}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: 'Last Reading',
      width: '10%',
      cellContent: ({ reading, last_reading, type, reading_timestamp }) => {
        const lastReading = reading || last_reading;
        const sensorTypes = getSensorTypes({ type });
        let displayedValue;
        if (reading && !isNaN(reading?.field) && reading?.field !== null) {
          displayedValue = transformTemperatureValue(
            reading?.field,
            currentUser
          );
        } else if (last_reading && last_reading?.field !== null) {
          const { temperature, humidity, state: binary } = JSON.parse(
            last_reading.field
          );
          if (sensorTypes.includes('AMBIENT')) {
            displayedValue = getTempPlusHumidity(
              temperature,
              currentUser,
              humidity,
              displayedValue
            );
          } else if (
            sensorTypes.includes(SensorType.TEMPERATURE) &&
            showTempPlusHumidity
          ) {
            displayedValue = getTempPlusHumidity(
              temperature,
              currentUser,
              humidity,
              displayedValue
            );
          } else if (sensorTypes.includes(SensorType.TEMPERATURE)) {
            displayedValue = transformTemperatureValue(
              temperature,
              currentUser
            );
          } else if (sensorTypes.includes(SensorType.HUMIDITY)) {
            displayedValue = `${humidity}%`;
          } else if (sensorTypes.includes(SensorType.BINARY)) {
            displayedValue = transformDoorValue(binary);
          } else {
            displayedValue = global.NOT_AVAILABLE;
          }
        } else {
          displayedValue = global.NOT_AVAILABLE;
        }
        return (
          <div className={tileStyles.tableFieldValue}>
            <TimestampTooltip
              timestamp={reading_timestamp?.field}
              siteId={siteId}
            >
              {displayedValue}
            </TimestampTooltip>
            {getErrorOrWarningIconForField(lastReading)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      width: '10%',
      header: 'Interval',
      cellContent: ({ interval_seconds }) => {
        const linkText =
          interval_seconds?.field !== undefined
            ? `${Math.floor(interval_seconds.field / 60)} mins`
            : global.NOT_AVAILABLE;
        return (
          <div className={tileStyles.tableFieldValue}>
            {linkToPairedSensorActions ? (
              !isWhiteListTab ? (
                <Link to={linkToPairedSensorActions} target="_blank">
                  {linkText}
                </Link>
              ) : (
                linkText
              )
            ) : (
              linkText
            )}
            {getErrorOrWarningIconForField(interval_seconds)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      width: '10%',
      header: 'UUID',
      cellContent: ({ id, UUID }) => (
        <div className={tileStyles.tableFieldValue}>
          {!isWhiteListTab ||
          (isWhitelistSensorNull && isWhitelistSensorNull(UUID?.field)) ? (
            isWhiteListTab &&
            isWhitelistSensorNull &&
            isWhitelistSensorNull(UUID?.field) ? (
              <Link
                to="#"
                onClick={() =>
                  props.displayNewSensorModal &&
                  props.displayNewSensorModal(siteId, UUID?.field)
                }
              >
                {UUID?.field || global.NOT_AVAILABLE}
              </Link>
            ) : (
              <Link
                to={`${Routes.DEVICES}${Routes.SENSORS}/${id?.field}`}
                target="_blank"
              >
                {UUID?.field || global.NOT_AVAILABLE}
              </Link>
            )
          ) : (
            UUID?.field || global.NOT_AVAILABLE
          )}
          {getErrorOrWarningIconForField(UUID)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'UUID',
          'field',
        ]);
      },
    },
    {
      header: 'Firmware Version',
      width: '10%',
      cellContent: ({ id, firmware_version }) => (
        <div className={tileStyles.tableFieldValue}>
          {firmware_version?.field || global.NOT_AVAILABLE}
          {getErrorOrWarningIconForField(firmware_version)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: 'Signal',
      width: '5%',
      cellContent: ({ signal_strength }) => (
        <div className={tileStyles.tableFieldValue}>
          {!!signal_strength && signal_strength?.field !== null
            ? `${signal_strength?.field}dBm`
            : global.NOT_AVAILABLE}
          {getErrorOrWarningIconForField(signal_strength)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: 'Power',
      width: '5%',
      cellContent: ({ battery_level, battery_voltage }) => (
        <div className={tileStyles.tableFieldValue}>
          {battery_voltage && battery_voltage.field !== null
            ? `${battery_voltage?.field}mV`
            : null}
          {!battery_voltage && !!battery_level && battery_level?.field !== null
            ? `${Math.round(battery_level?.field * 100)}%`
            : null}
          {getErrorOrWarningIconForField(battery_level)}
          {getErrorOrWarningIconForField(battery_voltage)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'battery_level',
          'field',
        ]);
      },
    },
  ];
  return columns.filter(column =>
    !showColumns.length ? true : showColumns.includes(column.header)
  );
};

const SensorsTile: React.FC<Props> = ({
  siteId,
  data,
  statisticsData,
  detailLevel,
  jumpToRef,
}) => {
  const currentUser = useCurrentUser();
  if (!currentUser) return null;

  const columns = getSensorsTableColumns({
    data,
    detailLevel,
    siteId,
    currentUser,
  });
  const tableData =
    data.filter(
      item =>
        item &&
        (detailLevel === InspectionDetailLevel.ALL ||
          doesItemContainErrorOrWarning(item))
    ) || [];
  const shouldHideTable =
    detailLevel === InspectionDetailLevel.ISSUES && !tableData.length;
  const summaryFields = getSensorsOrActuatorsSummaryFields(data, {
    statisticsData,
    isUnpaired: true,
  });
  if (
    detailLevel === InspectionDetailLevel.ISSUES &&
    summaryFields.status === InspectionStatus.GOOD
  ) {
    return null;
  }

  return (
    <InspectionTile
      title={InspectionComponentName.SENSORS_NEARBY}
      titleIcon={<ThermostatIcon variant="small" size="20" />}
      summaryFields={summaryFields}
      detailTable={
        shouldHideTable
          ? undefined
          : {
              dataIsLoading: false,
              columns,
              data: tableData,
            }
      }
      jumpToRef={jumpToRef}
    />
  );
};

function getTempPlusHumidity(
  temperature: number,
  currentUser: CurrentUser,
  humidity: number,
  displayedValue: string
) {
  const temperatureDisplayValue = !isNaN(temperature)
    ? transformTemperatureValue(temperature, currentUser)
    : null;
  const humidityDisplayValue = !isNaN(humidity) ? `${humidity}%` : null;
  if (temperatureDisplayValue && humidityDisplayValue) {
    displayedValue = `${temperatureDisplayValue} @ ${humidityDisplayValue}`;
  } else if (humidityDisplayValue) {
    displayedValue = humidityDisplayValue;
  } else if (temperatureDisplayValue) {
    displayedValue = temperatureDisplayValue;
  } else {
    displayedValue = global.NOT_AVAILABLE;
  }
  return displayedValue;
}

export default SensorsTile;
