import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { Space } from '@energybox/react-ui-library/dist/types';
import { classNames, hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions,
  collectSpacesRecursive,
  getSpaces,
  showNewModal,
} from '../../actions/spaces';
import { ApplicationState } from '../../reducers';
import { sortByTitleWithEmptyStringToLast } from '../../utils/sorting';
import { IoIosAddCircle } from 'react-icons/io';
import styles from './SelectSpace.module.css';
import NewSpaceItem from '../Space/NewSpaceItem';
import { useLocation } from 'react-router-dom';

interface Props {
  onSelect: (siteId: number) => void;
  value?: number;
  siteId: number;
  error?: boolean;
  customErrorText?: string;
  testResultsExist?: boolean;
  disabled?: boolean;
  allowCreateNew?: boolean;
}

const SelectSpace: React.FC<Props> = props => {
  const {
    onSelect,
    value,
    siteId,
    error,
    customErrorText,
    testResultsExist,
    disabled,
    allowCreateNew = false,
  } = props;
  const location = useLocation();
  console.debug('location', location);
  const [search, setSearch] = useState('');
  const [createNew, setCreateNew] = useState<boolean>(false);
  const inDetailPageWrapperStyle = location.pathname.startsWith(
    '/devices/network-groups/'
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const spaces = useSelector(({ spaces }: ApplicationState) =>
    collectSpacesRecursive(siteId, spaces.spaceIdsByParentId, spaces.spacesById)
      .filter(item => item !== undefined)
      .sort(sortByTitleWithEmptyStringToLast)
  );

  const createdId = useSelector(
    ({ spaces }: ApplicationState) => spaces.createdId
  );

  useEffect(() => {
    if (siteId && siteId !== -1) {
      dispatch(getSpaces({ siteIds: [siteId] }));
    }
  }, [siteId]);

  useEffect(() => {
    if (createNew) {
      dispatch(showNewModal(String(siteId)));
      // close the dropdown by simulating a mousedown event outside it
      const mouseEvent = document.createEvent('MouseEvents');
      mouseEvent.initEvent('mousedown', true, true);
      containerRef.current?.dispatchEvent(mouseEvent);
    }
  }, [createNew]);

  const searchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const resetSearch = () => {
    setSearch('');
  };

  const onClose = () => {
    setCreateNew(false);
    document.getElementById('selectSpace')?.click();
  };

  const filtereSpaces = spaces => {
    return spaces.filter((space: Space) => {
      if (search.length <= 2) return true;
      return hasSubstr(space.title, search);
    });
  };

  const isSiteSelected = () => {
    if (siteId !== -1) {
      return true;
    }
    return false;
  };

  // render starts here
  if (!spaces) {
    return <Loader size={12} />;
  } else {
    const selectedEquipmentSpace = spaces.find(space => space.id === value);
    const filteredSpaces = filtereSpaces(spaces);
    return (
      <>
        <Select
          id={'selectSpace'}
          variant={'select'}
          disabled={disabled || !isSiteSelected() || testResultsExist}
          onClick={resetSearch}
          title={selectedEquipmentSpace?.title}
          value={selectedEquipmentSpace?.id}
          error={error}
          customErrorText={customErrorText}
        >
          <SelectSearch
            onChange={searchChange}
            value={search}
            error={filteredSpaces.length === 0}
          />
          {filteredSpaces.map((space: Space) => (
            <SelectItem
              key={space.id}
              isSelected={
                space.id === selectedEquipmentSpace?.id ||
                space.id === createdId
              }
              onSelect={() => onSelect(space.id)}
            >
              {space.title}
            </SelectItem>
          ))}

          {allowCreateNew && (
            <button
              className={styles.addSpaceButton}
              onClick={() => {
                dispatch({
                  type: Actions.UPDATE_FIELD,
                  id: 'new',
                  field: 'parentId',
                  value: siteId,
                });
                setCreateNew(!createNew);
              }}
            >
              <IoIosAddCircle size="20" />
              <span className={styles.spaceText}>Add Space</span>
            </button>
          )}
        </Select>
        {createNew && (
          <div
            className={
              inDetailPageWrapperStyle
                ? styles.inDetailPage
                : styles.dropdownContainer
            }
            ref={containerRef}
          >
            <NewSpaceItem
              className={styles.createSpaceForm}
              siteId={siteId}
              onClose={() => onClose()}
            />
          </div>
        )}
      </>
    );
  }
};

export default SelectSpace;
