import {
  Checkbox,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { Actions as DistributionPanelActions } from '../../actions/distribution_panel';
import SelectDistributionPanelType from '../../containers/Selects/SelectDistributionPanelType';
import SelectSite from '../../containers/Selects/SelectSite';
import SelectSpace from '../../containers/Selects/SelectSpace';
import { EditableFields } from '../../reducers/distribution_panels';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import ModalFormContent from '../ModalFormContent';
import styles from './EditDistributionPanelForm.module.css';

interface Props {
  fields: EditableFields;
  onChange: (field: string, value: any) => void;
  siteId?: number;
  lockSiteId?: number;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
  isChanged?: boolean;
  patchingPanel?: boolean;
  getSiteId?: (siteId: number) => void;
}

const EditDistributionPanelForm = ({
  fields,
  onChange,
  formErrors,
  formErrorsVisible,
  apiError,
  patchingPanel,
  lockSiteId,
  siteId = -1,
  isChanged,
  getSiteId,
}: Props) => {
  const [siteIdInState, setSiteId] = React.useState(lockSiteId || siteId);
  const [showSlotsError, setShowSlotsError] = React.useState(false);

  React.useEffect(() => {
    // Case where form is rest to initial state
    if (isChanged !== undefined && !isChanged && siteId) {
      setSiteId(siteId);
    }
  }, [siteId, isChanged]);

  const { title, description, spaceId, breakerSlots, type, mdp } = fields;

  const onChangeBreakerSlots = (value: string) => {
    if (parseInt(value) < 0) {
      onChange('breakerSlots', 0);
      setShowSlotsError(true);
    } else if (parseInt(value) > 35) {
      onChange('breakerSlots', 35);
      setShowSlotsError(true);
    } else {
      onChange('breakerSlots', parseInt(value));
      setShowSlotsError(false);
    }
  };

  return (
    <div>
      <ModalFormContent>
        <div>
          <Label required htmlFor="title">
            Panel Name
          </Label>
        </div>
        <div>
          <InputField
            id="title"
            type="text"
            name="title"
            value={title}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
          />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
        </div>
        <div>
          <InputField
            id="description"
            type="text"
            name="description"
            placeholder=""
            value={description}
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        <div>
          <Label required htmlFor="siteId">
            Site
          </Label>
        </div>
        <div>
          <SelectSite
            disabled={patchingPanel || !!lockSiteId}
            onSelect={selectedSiteId => {
              setSiteId(selectedSiteId);
              getSiteId && getSiteId(selectedSiteId);
            }}
            value={siteIdInState}
          />
        </div>

        <div>
          <Label required htmlFor="spaceId">
            Location
          </Label>
        </div>
        <div>
          <SelectSpace
            onSelect={spaceId => onChange('spaceId', spaceId)}
            value={spaceId}
            siteId={siteIdInState}
            error={formErrorsVisible && !!formErrors.spaceId}
            customErrorText="Please select a location."
          />
        </div>

        <div>
          <Label htmlFor="mdp">Main Panel</Label>
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            id="mdp"
            name="mdp"
            onChange={() => onChange('mdp', !mdp)}
            checked={mdp}
          />
        </div>

        <div>
          <Label required htmlFor="type">
            Panel Type
          </Label>
        </div>
        <div>
          <SelectDistributionPanelType
            disabled={patchingPanel}
            error={formErrorsVisible && !!formErrors.type}
            value={type}
            onSelect={panelType => onChange('type', panelType)}
          />
        </div>

        <div>
          <Label required htmlFor="breakerSlots">
            Slots Per Column
          </Label>
        </div>
        <div className={styles.breakerSlotInputFieldLength}>
          <InputField
            id="breakerSlots"
            type="number"
            name="breaker slots"
            placeholder=""
            value={breakerSlots}
            onChange={e => onChangeBreakerSlots(e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.breakerSlots}
          />
        </div>

        <div />
        {showSlotsError && (
          <div className={styles.slotsError}>
            Slots per column must be between 0 and 35.
          </div>
        )}
      </ModalFormContent>
      {renderAPIerror(
        apiError,
        DistributionPanelActions.PATCH_DISTRIBUTION_PANEL_ERROR,
        DistributionPanelActions.CREATE_DISTRIBUTION_PANEL_ERROR
      )}
      <ModalFormContent>
        <Label>* Mandatory fields</Label>
      </ModalFormContent>
    </div>
  );
};

export default EditDistributionPanelForm;
