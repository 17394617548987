import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getUrlStateParams } from './utils';
import history from '../history';
import { areArraysIdentical } from '../utils/areArraysIdentical';
import mixpanel from 'mixpanel-browser';

const useSiteFilter = (onChange?: (updatedSiteIds: number[]) => void) => {
  const dispatch = useDispatch();

  const search = history?.location?.search;
  const selectedSiteFilters = useMemo(
    () => getUrlStateParams<number[]>(history, 'sites', []).map(Number),
    [search]
  );

  const setFilter: (siteIds: number[]) => void = useCallback(
    (updatedSiteIds: number[]) => {
      const sitesMappedToFilterHaveNotChanged = areArraysIdentical(
        updatedSiteIds,
        selectedSiteFilters
      );

      if (sitesMappedToFilterHaveNotChanged) {
        return;
      }

      mixpanel?.track('Filter Applied', {
        type: 'Site',
        sites: updatedSiteIds,
      });

      setSites(updatedSiteIds);
    },
    [dispatch, onChange, selectedSiteFilters]
  );

  const isSiteFilterActive = selectedSiteFilters.length > 0;

  return {
    setFilter,
    selectedSiteFilters,
    isSiteFilterActive,
  };
};

export default useSiteFilter;

const setSites = siteIds => {
  const pathname = history?.location?.pathname;
  const updatedSearchParams = new URLSearchParams(history?.location?.search);

  updatedSearchParams.delete('siteGroups');

  if (Array.isArray(siteIds)) {
    updatedSearchParams.delete('sites');
    siteIds.forEach(value => {
      updatedSearchParams.append('sites', value);
    });
  }

  history?.push({ pathname, search: updatedSearchParams.toString() });
};
