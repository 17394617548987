import { SubscribedControlBoards } from '@energybox/react-ui-library/dist/types/SubscribedControlBoard';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions as StreamActions } from '../actions/streamApi';

const initialState = {
  byId: {},
};

const subscribedControlBoards = (
  state: SubscribedControlBoards = initialState,
  action: any
) => {
  if (action.type === StreamActions.RECEIVED_DEVICE_STATUS) {
    if (
      action.data.vendor !== 'energybox' ||
      action.data.status === undefined ||
      action.data.status.gatewayStates === undefined
    ) {
      return state;
    }

    const controlBoard = action.data;

    return pipe(assocPath(['byId', action.data.id.toString()], controlBoard))(
      state
    );
  } else {
    return state;
  }
  // switch (action.type) {
  //   case StreamActions.RECEIVED_DEVICE_STATUS: {
  //     if (
  //       action.data.vendor !== 'energybox' ||
  //       action.data.status === undefined ||
  //       action.data.status.gatewayStates === undefined
  //     ) {
  //       return state;
  //     }

  //     const controlBoard = action.data;

  //     return pipe(assocPath(['byId', action.data.id.toString()], controlBoard))(
  //       state
  //     );
  //   }

  //   default:
  //     return state;
  // }
};

export default subscribedControlBoards;
