import {
  ResourcePath as ResourcePathType,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getResourcePathById } from '../../actions/paths';
import { ApplicationState } from '../../reducers';
import { PathsById } from '../../reducers/paths';
import { IsLoadingByResourceId } from '../../reducers/energy';
import { global } from '@energybox/react-ui-library/dist/utils';

type Props = {
  resourcePathsById: PathsById;
  resourcePathLoadingById: IsLoadingByResourceId;
  resourceId: number | string;
  resourceType: ResourceType;
  getResourcePathById: typeof getResourcePathById;
  suppressLink?: boolean;
  openInNewTab?: boolean;
};

const ResourcePath: React.FunctionComponent<Props> = ({
  getResourcePathById,
  resourceId,
  resourceType,
  resourcePathsById = [],
  resourcePathLoadingById,
  suppressLink,
  openInNewTab = false,
}) => {
  const path: ResourcePathType[] | undefined = resourcePathsById[resourceId];
  const isLoading: boolean = resourcePathLoadingById[resourceId];

  React.useEffect(() => {
    if (!path) {
      getResourcePathById(+resourceId);
    }
  }, [getResourcePathById, resourceId, path]);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (path && path.length) {
    const [site, ...rest] = path;

    return (
      <div>
        <div>
          {suppressLink ? (
            <strong>{site.title}</strong>
          ) : (
            <Link
              to={`/sites/${site.id}`}
              target={openInNewTab ? '_blank' : '_self'}
            >
              <strong>{site.title}</strong>
            </Link>
          )}
        </div>

        {rest
          .filter(p => p.type === resourceType)
          .map(r => r.title)
          .join(' > ')}
      </div>
    );
  }

  return <>{global.NOT_AVAILABLE}</>;
};

const mapStateToProps = ({ resourcePaths }: ApplicationState) => ({
  resourcePathsById: resourcePaths.byId,
  resourcePathLoadingById: resourcePaths.isLoadingById,
});

const mapDispatchToProps = {
  getResourcePathById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePath);
