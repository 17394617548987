import { omit } from 'ramda';

import { ApplicationState } from '../reducers';

import { getSiteByResourceId } from './sites';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';
import { NetworkGroupsFromApiResponse } from '../reducers/network_groups';

const apiBase = '/api/v1/network-groups';

export enum Actions {
  GET_NETWORK_GROUP_SUCCESS = '@network_group/GET_NETWORK_GROUP_SUCCESS',
  GET_NETWORK_GROUP_ERROR = '@network_group/GET_NETWORK_GROUP_ERROR',
  GET_NETWORK_GROUP_LOADING = '@network_group/GET_NETWORK_GROUP_LOADING',

  GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS = '@network_group/GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS',
  GET_NETWORK_GROUP_BY_SITE_ID_ERROR = '@network_group/GET_NETWORK_GROUP_BY_SITE_ID_ERROR',
  GET_NETWORK_GROUP_BY_SITE_ID_LOADING = '@network_group/GET_NETWORK_GROUP_BY_SITE_ID_LOADING',

  GET_NETWORK_GROUP_BY_EQUIPMENT_ID_SUCCESS = '@network_group/GET_NETWORK_GROUP_BY_EQUIPMENT_ID_SUCCESS',
  GET_NETWORK_GROUP_BY_EQUIPMENT_ID_ERROR = '@network_group/GET_NETWORK_GROUP_BY_EQUIPMENT_ID_ERROR',
  GET_NETWORK_GROUP_BY_EQUIPMENT_ID_LOADING = '@network_group/GET_NETWORK_GROUP_BY_EQUIPMENT_ID_LOADING',

  GET_NETWORK_GROUP_BY_EDGE_UUID_SUCCESS = '@network_group/GET_NETWORK_GROUP_BY_EDGE_UUID_SUCCESS',
  GET_NETWORK_GROUP_BY_EDGE_UUID_ERROR = '@network_group/GET_NETWORK_GROUP_BY_EDGE_UUID_ERROR',
  GET_NETWORK_GROUP_BY_EDGE_UUID_LOADING = '@network_group/GET_NETWORK_GROUP_BY_EDGE_UUID_LOADING',

  GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_SUCCESS = '@network_group/GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_SUCCESS',
  GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_ERROR = '@network_group/GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_ERROR',
  GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_LOADING = '@network_group/GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_LOADING',

  CREATE_NETWORK_GROUP_LOADING = '@network_group/CREATE_NETWORK_GROUP_LOADING',
  CREATE_NETWORK_GROUP_SUCCESS = '@network_group/CREATE_NETWORK_GROUP_SUCCESS',
  CREATE_NETWORK_GROUP_ERROR = '@network_group/CREATE_NETWORK_GROUP_ERROR',

  PATCH_NETWORK_GROUP_LOADING = '@network_group/PATCH_NETWORK_GROUP_LOADING',
  PATCH_NETWORK_GROUP_ERROR = '@network_group/PATCH_NETWORK_GROUP_ERROR',
  PATCH_NETWORK_GROUP_SUCCESS = '@network_group/PATCH_NETWORK_GROUP_SUCCESS',

  DELETE_NETWORK_GROUP_SUCCESS = '@network_group/DELETE_NETWORK_GROUP_SUCCESS',
  DELETE_NETWORK_GROUP_ERROR = '@network_group/DELETE_NETWORK_GROUP_ERROR',
  DELETE_NETWORK_GROUP_LOADING = '@network_group/DELETE_NETWORK_GROUP_LOADING',

  TOGGLE_NEW_NETWORK_GROUP_MODAL = '@network_group/TOGGLE_NEW_NETWORK_GROUP_MODAL',

  UPDATED_QUERY = '@network_group/UPDATED_QUERY',

  UPDATE_FIELD = '@network_group/UPDATE_FIELD',
  RESET_EDIT_NETWORK_GROUP = '@network_group/RESET_EDIT_NETWORK_GROUP',

  DISPLAY_FORM_ERRORS = '@network_group/DISPLAY_FORM_ERRORS',
  CLEAR_FORM_ERRORS = '@network_group/CLEAR_FORM_ERRORS',

  GET_NETWORK_GROUPS_BY_SOP_ID_SUCCESS = '@network_group/GET_NETWORK_GROUPS_BY_SOP_ID_SUCCESS',
  GET_NETWORK_GROUPS_BY_SOP_ID_ERROR = '@network_group/GET_NETWORK_GROUPS_BY_SOP_ID_ERROR',
  GET_NETWORK_GROUPS_BY_SOP_ID_LOADING = '@network_group/GET_NETWORK_GROUPS_BY_SOP_ID_LOADING',

  GET_NETWORK_GROUPS_BY_ORG_ID_SUCCESS = '@network_group/GET_NETWORK_GROUPS_BY_ORG_ID_SUCCESS',
  GET_NETWORK_GROUPS_BY_ORG_ID_ERROR = '@network_group/GET_NETWORK_GROUPS_BY_ORG_ID_ERROR',
  GET_NETWORK_GROUPS_BY_ORG_ID_LOADING = '@network_group/GET_NETWORK_GROUPS_BY_ORG_ID_LOADING',
}

export const getNetworkGroup = (networkGroupId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${networkGroupId}`,
  success: { type: Actions.GET_NETWORK_GROUP_SUCCESS, networkGroupId },
  error: { type: Actions.GET_NETWORK_GROUP_ERROR, networkGroupId },
  loading: { type: Actions.GET_NETWORK_GROUP_LOADING, networkGroupId },
});

export const getNetworkGroupBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/site/${siteId}`,
  success: { type: Actions.GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_NETWORK_GROUP_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING, siteId },
});

export const getNetworkGroupsByOrgId = (orgId: number) => ({
  type: 'API_GET',
  path: `${apiBase}/organization/${orgId}`,
  success: {
    type: Actions.GET_NETWORK_GROUPS_BY_ORG_ID_SUCCESS,
    orgId,
  },
  error: { type: Actions.GET_NETWORK_GROUPS_BY_ORG_ID_ERROR, orgId },
  loading: { type: Actions.GET_NETWORK_GROUPS_BY_ORG_ID_LOADING, orgId },
});

export const getNetworkGroupByEquipmentId = (
  equipmentId: number | undefined
) => ({
  type: 'API_GET',
  path: `${apiBase}/equipment-id/${equipmentId}/using-thermostat`,
  success: {
    type: Actions.GET_NETWORK_GROUP_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId,
  },
  error: { type: Actions.GET_NETWORK_GROUP_BY_EQUIPMENT_ID_ERROR, equipmentId },
  loading: {
    type: Actions.GET_NETWORK_GROUP_BY_EQUIPMENT_ID_LOADING,
    equipmentId,
  },
});

export const getNetworkGroupsBySopId = (
  sopId: number | string,
  orgId: number | string,
  options?: { produceEdgeConfig?: boolean }
) => ({
  type: 'API_GET',
  path: `${apiBase}/organization/${orgId}/sop/${sopId}`,
  success: {
    type: Actions.GET_NETWORK_GROUPS_BY_SOP_ID_SUCCESS,
    sopId,
    options,
  },
  error: { type: Actions.GET_NETWORK_GROUPS_BY_SOP_ID_ERROR, sopId },
  loading: { type: Actions.GET_NETWORK_GROUPS_BY_SOP_ID_LOADING, sopId },
});

export const getNetworkGroupByEdgeUuid = (uuid: string) => ({
  type: 'API_GET',
  path: `${apiBase}/edge-uuid/${uuid}`,
  success: { type: Actions.GET_NETWORK_GROUP_BY_EDGE_UUID_SUCCESS, uuid },
  error: { type: Actions.GET_NETWORK_GROUP_BY_EDGE_UUID_ERROR, uuid },
  loading: { type: Actions.GET_NETWORK_GROUP_BY_EDGE_UUID_LOADING, uuid },
});

export const getNetworkGroupByEdgeSerialNumber = (serialNumber: string) => ({
  type: 'API_GET',
  path: `${apiBase}/edge-serial-number/${serialNumber}`,
  success: {
    type: Actions.GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_SUCCESS,
    serialNumber,
  },
  error: {
    type: Actions.GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_ERROR,
    serialNumber,
  },
  loading: {
    type: Actions.GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_LOADING,
    serialNumber,
  },
});

export const patch = (id: string) => (dispatch, getState) => {
  // Keep serialNumber unchanged when editing Netwrok Group for SuperHub
  const networkGroups = (getState() as ApplicationState).networkGroups;
  let editNetworkGroupFields = networkGroups.editById[id].fields;

  if (editNetworkGroupFields.edge.edgeDevice === 'EB_SUPER_HUB') {
    const originalNetworkGroupFields = networkGroups.networkGroupsById[id];
    if (originalNetworkGroupFields.edge?.serialNumber) {
      editNetworkGroupFields.edge.serialNumber =
        originalNetworkGroupFields.edge.serialNumber;
    } else {
      const edge = editNetworkGroupFields.edge;
      editNetworkGroupFields.edge = omit(['serialNumber'], edge);
    }
  }

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: editNetworkGroupFields,
    loading: { type: Actions.PATCH_NETWORK_GROUP_LOADING, id },
    success: [
      { type: Actions.PATCH_NETWORK_GROUP_SUCCESS, id },
      getSiteByResourceId(id),
    ],
    error: { type: Actions.PATCH_NETWORK_GROUP_ERROR, id },
  });
};

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_NETWORK_GROUP_LOADING, id },
  success: { type: Actions.DELETE_NETWORK_GROUP_SUCCESS, id },
  error: { type: Actions.DELETE_NETWORK_GROUP_ERROR, id },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_NETWORK_GROUP,
  id,
});

export const updateQuery = (query: string) => ({
  type: Actions.UPDATED_QUERY,
  query,
});

export const showNewNetworkGroupModal = () => ({
  type: Actions.TOGGLE_NEW_NETWORK_GROUP_MODAL,
  value: true,
});

export const hideNewNetworkGroupModal = () => ({
  type: Actions.TOGGLE_NEW_NETWORK_GROUP_MODAL,
  value: false,
});

export const displayFormErrors = (id?: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const clearFormErrors = () => {
  return {
    type: Actions.CLEAR_FORM_ERRORS,
  };
};

// Since this is nested, we accept fields that can be split by '.' and used as a path
export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const create = (
  siteId?: number,
  callback?: (networkGroup: NetworkGroup) => void
) => (dispatch, getState) => {
  // Omit serialNumber when creating Netwrok Group for SuperHub
  let newNetworkGroupFields = (getState() as ApplicationState).networkGroups
    .editById['new'].fields;
  let edge = newNetworkGroupFields.edge;
  if (newNetworkGroupFields.edge.edgeDevice === 'EB_SUPER_HUB') {
    newNetworkGroupFields.edge = omit(['serialNumber'], edge);
  }

  dispatch({
    type: 'API_POST',
    path: apiBase,
    payload: {
      ...newNetworkGroupFields,
      siteId,
    },
    loading: Actions.CREATE_NETWORK_GROUP_LOADING,
    success: [
      Actions.CREATE_NETWORK_GROUP_SUCCESS,
      siteId ? getNetworkGroupBySiteId(String(siteId)) : '',
    ],
    error: Actions.CREATE_NETWORK_GROUP_ERROR,
    ...(callback
      ? {
          callback: (res: any) => {
            if (!('error' in res)) {
              const networkGroup = NetworkGroupsFromApiResponse(res);
              callback(networkGroup);
            }
          },
        }
      : {}),
  });
};
