import {
  DeviceType,
  Edge,
  GenericErrors,
  IdListMapping,
  LoadingById,
  NetworkGroup,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/network_groups';
import { Actions as GatewayAction } from '../actions/gateways';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import { EdgeDeviceModel } from '@energybox/react-ui-library/dist/types/NetworkGroup';

export interface EditableFields {
  title: string;
  description: string;
  edgeDevice: EdgeDeviceModel | '';
  edge: Edge;
  siteId: number;
  spaceId?: number;
  northUuid?: string;
  southUuid?: string;
}
const editableFields = (networkGroup: object) =>
  R.pick(
    [
      'title',
      'description',
      'edgeDevice',
      'edge',
      'siteId',
      'northUuid',
      'southUuid',
    ],
    networkGroup
  );

export type EditNetworkGroup = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const newNetworkGroupFields = {
  title: '',
  description: '',
  edgeDevice: '',
  siteId: -1,
  edge: {},
};

const newNetworkGroup = {
  isLoading: false,
  isChanged: false,
  fields: newNetworkGroupFields,
  formErrors: formValidationErrors('networkGroup', newNetworkGroupFields),
  formErrorsVisible: false,
  apiError: {},
};

export type EditById = {
  [id: string]: EditNetworkGroup;
};

export type ActionsLoadingStatus = {
  [Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING]?: LoadingById;
  [Actions.GET_NETWORK_GROUP_LOADING]?: LoadingById;
};

export type NetworkGroupsByEdgeSerialNumber = {
  [edgeSerialNumber: string]: NetworkGroup;
};

export type NetworkGroupsByEdgeUuid = {
  [edgeUuid: string]: NetworkGroup;
};

export type NetworkGroupsByEquipmentid = {
  [equipmentId: string]: NetworkGroup[];
};

export type NetworkGroupsBySopId = {
  [sopId: string]: NetworkGroup[];
};

export type NetworkGroupsByOrgId = {
  [orgId: string]: NetworkGroup[];
};

export interface NetworkGroups {
  query: string;
  networkGroupsById: { [id: string]: NetworkGroup };
  showNewNetworkGroupModal: boolean;
  networkGroupsByEdgeUuid: NetworkGroupsByEdgeUuid;
  networkGroupsByEquipmentId: NetworkGroupsByEquipmentid;
  editById: EditById;
  networkGroupIdsBySiteId: IdListMapping;
  loadingStatusByAction: ActionsLoadingStatus;
  networkGroupsByEdgeSerialNumber: NetworkGroupsByEdgeSerialNumber;
  networkGroupsBySopId: NetworkGroupsBySopId;
  networkGroupsByOrgId: NetworkGroupsByOrgId;
  lastCreatedId?: string;
  networkGroupApiError: ApiError;
}

export const NetworkGroupsFromApiResponse = (data: any): NetworkGroup => ({
  networkGroupId: data.id,
  id: data.id,
  title: data.title,
  description: data.description,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  edge: data.edge || {},
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  siteId: data.siteId,
  site: data.site,
});

export const networkGroupsFromSiteId = (
  networkGroupsState: NetworkGroups,
  siteId?: string
): NetworkGroup[] => {
  if (!networkGroupsState || !networkGroupsState.networkGroupIdsBySiteId) {
    return [];
  }
  const { networkGroupIdsBySiteId, networkGroupsById } = networkGroupsState;

  if (!siteId || !networkGroupIdsBySiteId[siteId]) return [];

  return networkGroupIdsBySiteId[siteId].map(id => networkGroupsById[id]);
};

export const initialState: NetworkGroups = {
  query: '',
  networkGroupsById: {},
  networkGroupsByEdgeUuid: {},
  showNewNetworkGroupModal: false,
  editById: {},
  networkGroupIdsBySiteId: {},
  networkGroupsByEquipmentId: {},
  loadingStatusByAction: {},
  networkGroupsByEdgeSerialNumber: {},
  networkGroupsBySopId: {},
  networkGroupsByOrgId: {},
  networkGroupApiError: {},
};

const networkGroups = (state: NetworkGroups = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_NETWORK_GROUPS_BY_SOP_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['networkGroupsBySopId', action.sopId],
          action.data?.map(ng => NetworkGroupsFromApiResponse(ng))
        )
      )(state);

    case Actions.GET_NETWORK_GROUPS_BY_ORG_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['networkGroupsByOrgId', action.orgId],
          action.data?.map(ng => NetworkGroupsFromApiResponse(ng))
        )
      )(state);

    case Actions.GET_NETWORK_GROUP_BY_EDGE_UUID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['networkGroupsByEdgeUuid', action.uuid],
          NetworkGroupsFromApiResponse(action.data)
        )
      )(state);

    case Actions.GET_NETWORK_GROUP_BY_EQUIPMENT_ID_SUCCESS:
      const newState = R.assocPath(
        ['networkGroupsByEquipmentId', action.equipmentId],
        action.data?.map(ng => NetworkGroupsFromApiResponse(ng)),
        state
      );
      return newState;

    case Actions.GET_NETWORK_GROUP_BY_EDGE_SERIAL_NUMBER_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['networkGroupsByEdgeSerialNumber', action.serialNumber],
          NetworkGroupsFromApiResponse(action.data)
        )
      )(state);

    case Actions.GET_NETWORK_GROUP_SUCCESS:
      let networkGroup = NetworkGroupsFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['networkGroupsById', action.data.id], networkGroup),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(networkGroup),
          apiError: {},
        }),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_NETWORK_GROUP_LOADING,
            action.networkGroupId,
          ],
          false
        ),
        R.assocPath(['networkGroupApiError'], {})
      )(state);

    case Actions.GET_NETWORK_GROUP_LOADING:
      return R.pipe(
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_NETWORK_GROUP_LOADING,
            action.networkGroupId,
          ],
          true
        ),
        R.assocPath(['networkGroupApiError'], {})
      )(state);

    case Actions.GET_NETWORK_GROUP_ERROR:
      return R.pipe(
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_NETWORK_GROUP_LOADING,
            action.networkGroupId,
          ],
          false
        ),
        R.assocPath(['networkGroupApiError'], action.data)
      )(state);

    case Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING,
          action.siteId,
        ],
        true,
        state
      );

    case Actions.GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['networkGroupIdsBySiteId', [action.siteId]],
          action.data.map(({ id }) => String(id))
        ),
        R.assoc(
          'networkGroupsById',
          R.mergeRight(
            R.view(R.lensProp('networkGroupsById'), state),
            mapArrayToObject(
              mapValues(action.data, NetworkGroupsFromApiResponse)
            )
          )
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING,
            [action.siteId],
          ],
          false
        )
      )(state);

    case Actions.TOGGLE_NEW_NETWORK_GROUP_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newNetworkGroup),
        R.assoc('showNewNetworkGroupModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      if (action.id) {
        return R.assocPath(
          ['editById', action.id, 'formErrorsVisible'],
          action.value,
          state
        );
      } else {
        return R.assocPath(
          ['editById', 'new', 'formErrorsVisible'],
          action.value,
          state
        );
      }

    case Actions.CLEAR_FORM_ERRORS:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], {}),
        R.assocPath(['editById', 'new', 'formErrors'], {})
      )(state);

    case Actions.UPDATE_FIELD:
      let updatedField = R.assocPath(
        R.split('.', action.field),
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );

      if (
        action.field === 'edge.serialNumber' &&
        updatedField?.edge?.uuid?.length
      ) {
        updatedField = R.assocPath(['edge', 'uuid'], '', updatedField);
      }

      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('networkGroup', updatedField)
        )
      )(state);

    case Actions.CREATE_NETWORK_GROUP_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_NETWORK_GROUP_SUCCESS: {
      const isSuperHub =
        action.data.edge?.edgeDevice === DeviceType.EB_SUPER_HUB;
      return R.pipe(
        R.assocPath(
          ['networkGroupsByOrgId', [action.data.site.organizationId]],
          R.append(
            NetworkGroupsFromApiResponse(action.data),
            state.networkGroupsByOrgId[action.data.site.organizationId]
          )
        ),
        R.assocPath(
          ['networkGroupIdsBySiteId', [action.siteId]],
          R.append(
            NetworkGroupsFromApiResponse(action.data).id,
            state.networkGroupIdsBySiteId[action.siteId]
          )
        ),
        R.assocPath(
          ['networkGroupsById', action.data.id],
          NetworkGroupsFromApiResponse(action.data)
        ),
        isSuperHub
          ? s => s
          : R.assocPath(['editById', 'new', 'isLoading'], false),
        isSuperHub ? s => s : R.assoc('showNewNetworkGroupModal', false),
        R.assoc('lastCreatedId', action.data.id)
      )(state);
    }

    case Actions.CREATE_NETWORK_GROUP_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case GatewayAction.CREATE_GATEWAY_ERROR:
      if (state.editById.new) {
        return R.pipe(
          R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
          R.assocPath(['editById', 'new', 'isLoading'], false)
        )(state);
      } else {
        return state;
      }

    case GatewayAction.CREATE_GATEWAY_SUCCESS:
      return R.pipe(
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewNetworkGroupModal', false)
      )(state);

    case Actions.PATCH_NETWORK_GROUP_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_NETWORK_GROUP_SUCCESS:
      const patchedNetworkGroup = NetworkGroupsFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['networkGroupsById', action.id], patchedNetworkGroup),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_NETWORK_GROUP_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_NETWORK_GROUP:
      const fields = editableFields(
        R.path(['networkGroupsById', action.id], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(['editById', action.id, 'apiError'], {}),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('networkGroup', fields)
        )
      )(state);

    case Actions.DELETE_NETWORK_GROUP_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    // TODO: need to remove from networkGroupIdsBySiteId
    case Actions.DELETE_NETWORK_GROUP_SUCCESS:
      return R.pipe(
        R.dissocPath(['networkGroupsById', action.id]),
        R.dissocPath(['editById', action.id])
      )(state);

    case Actions.DELETE_NETWORK_GROUP_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    default:
      return state;
  }
};

export default networkGroups;
