import {
  DateFilter,
  EnergyLineChart,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import { EnergyLineChartType } from '@energybox/react-ui-library/dist/components/EnergyLineChart';
import {
  AggregationFilter,
  CircuitBreakerFromApiResponse,
  EnergyPowerApiResponse,
  SentinelType,
  Site,
} from '@energybox/react-ui-library/dist/types';
import {
  processThresholdLines,
  TIME_IN_MILLISECONDS,
} from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';

import React, { useMemo, useState } from 'react';
import ChartResolutionFilter from '../../components/Filters/ChartResolutionFilter';
import EnergyChartTypeToggle from '../EnergyChartTypeToggle';
import { useAppLocale, useCurrentUser } from '../../hooks/useAppDetails';
import useChartDetails, { ChartDetailInfo } from '../../hooks/useChartDetails';
import useGetEnergyByEquipmentId from '../../hooks/useGetEnergyByEquipmentId';
import { useGetSentinelsByResourceId } from '../../hooks/useGetSentinels';
import styles from './EquipmentEnergyChart.module.css';
import { processedDataFromEnergyPowerApiResp } from '../../utils/timeSeries';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import ChartCardContainer from '../ChartCard/ChartCardContainer';

type Props = {
  equipmentId: number;
  syncId?: string;
  site: Site;
  globalChartDetails?: ChartDetailInfo;
  hideTitle?: boolean;
  hideThresholdLines?: boolean;
  chartType?: EnergyLineChartType;
};

//this chart can show either the power or current series (one or the other)
//determined by the chartType
const EquipmentEnergyChart: React.FC<Props> = ({
  hideTitle,
  hideThresholdLines,
  equipmentId,
  syncId,
  site,
  globalChartDetails,
  chartType,
}) => {
  const currentUser = useCurrentUser();
  const [localChartType, setLocalChartType] = useState<EnergyLineChartType>(
    EnergyLineChartType.POWER
  );
  const activeChartType = chartType || localChartType;
  const locale = useAppLocale();

  const chartDetailsFromHook = useChartDetails(locale, {
    includeAggregationFilter: true,
    timezone: site.timeZone,
    useCurrentTime: true,
  });

  const chartDetails = globalChartDetails || chartDetailsFromHook;

  const {
    dateFilterTitle,
    dateFilterFromDate,
    dateFilterToDate,
    fromDate,
    toDate,
    xTicks,
    setDateFilter,
    zoomIn,
    zoom,
    zoomRange,
    zoomOut,
    isZoomedIn,
    setRefAreaStart,
    setRefAreaEnd,
    refAreaStart,
    refAreaEnd,
    aggregationFilter,
    setAggregationFilter,
    isMonthly,
    isCustomRange,
  } = chartDetails;
  const fromDateMs = DateTime.fromISO(fromDate).toMillis();
  const toDateMs = DateTime.fromISO(toDate).toMillis();
  const dateFilterFromDateJS = DateTime.fromISO(dateFilterFromDate).toJSDate();
  const dateFilterToDateJS = DateTime.fromISO(dateFilterToDate).toJSDate();

  const energyQueryParams = useMemo(
    () => ({
      id: equipmentId,
      from: fromDate,
      to: toDate,
      aggregationLevel: aggregationFilter?.aggregationLevel,
      seeSpilloverPoint: true,
    }),
    [equipmentId, fromDate, toDate, aggregationFilter?.aggregationLevel]
  );

  const { isLoading, data } = useGetEnergyByEquipmentId(
    equipmentId,
    energyQueryParams,
    site.newTsdb,
    syncId
  );

  const sentinelQueryParams = useMemo(
    () => ({
      equipmentIds: [equipmentId],
    }),
    [equipmentId]
  );

  const equipmentSentinels = useGetSentinelsByResourceId(
    equipmentId,
    sentinelQueryParams
  );

  const thresholdLines = useMemo(() => {
    if (!currentUser || !equipmentSentinels) return [];
    return processThresholdLines(equipmentSentinels, currentUser, [
      SentinelType.ACTIVE_POWER_THRESHOLD,
    ]);
  }, [equipmentSentinels, currentUser]);

  const processedData = useMemo(() => {
    if (!data) return [];

    return processedDataFromEnergyPowerApiResp(data);
  }, [data]);

  const timeDelta = toDateMs - fromDateMs;
  const disableOneMinuteResolution = timeDelta > TIME_IN_MILLISECONDS.ONE_DAY;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div>
          {!hideTitle && (
            <div className={styles.title}>Equipment Energy Activity</div>
          )}
        </div>

        {!globalChartDetails && (
          <div className={styles.headerRightAlign}>
            <EnergyChartTypeToggle
              chartType={localChartType}
              setChartType={setLocalChartType}
            />

            {aggregationFilter && (
              <ChartResolutionFilter
                className={styles.chartResolutionFilterContainer}
                disableOneMinuteResolution={disableOneMinuteResolution}
                title={aggregationFilter.title}
                onChange={(aggFilter: AggregationFilter) =>
                  setAggregationFilter(aggFilter)
                }
              />
            )}

            <DateFilter
              alignItemsRight
              setFilter={setDateFilter}
              value={{
                title: dateFilterTitle,
                toDate: dateFilterToDateJS,
                fromDate: dateFilterFromDateJS,
                isMonthly,
                isCustomRange,
              }}
              dateFormat="datetime"
              customPickerVariant="datetime"
              ianaTimeZoneCode={site.timeZone}
              displayDatesAsTitle
              locale={locale}
            />

            <div className={styles.zoomResetContainer}>
              <ResetButton
                customText="Reset Zoom"
                displayInfoTooltip
                onClick={zoomOut}
                disabled={!isZoomedIn}
              />
            </div>
          </div>
        )}
      </header>

      <div>
        <EnergyLineChart
          syncId={syncId || String(equipmentId)}
          isLoading={isLoading}
          locale={locale}
          ianaTimeZoneCode={site.timeZone}
          fromDate={zoomRange.from}
          toDate={zoomRange.to}
          ticks={xTicks}
          data={processedData}
          thresholdLines={thresholdLines}
          zoom={zoom}
          hideThresholdLinesCheckbox={hideThresholdLines}
          hideNotificationsCheckbox
          hideCommentsCheckbox
          type={activeChartType}
          displayCurrentInLegend
        />
      </div>
    </div>
  );
};

interface CardProps {
  chartDetails: ChartDetailInfo;
  circuitBreaker: CircuitBreakerFromApiResponse;
  site: Site;
  chartType: EnergyLineChartType;
}

const withCard = Component => (props: CardProps) => {
  const { circuitBreaker, chartDetails, site, chartType } = props;

  const { zoomRange } = chartDetails;

  const equipBreakerId = `${circuitBreaker.equipmentId}_${circuitBreaker.id}`;

  const energyData = useSelector<ApplicationState, EnergyPowerApiResponse>(
    ({ energy }) => {
      return energy.energyByEquipmentId[equipBreakerId];
    }
  );

  const energyTotal: number | undefined = zoomRange
    ? energyData?.readings
        .filter(
          r =>
            r.timestamp * 1000 >= zoomRange.from &&
            r.timestamp * 1000 <= zoomRange.to
        )
        .reduce((sum, current) => sum + current.energy, 0)
    : energyData?.energyTotal;

  const subTitle =
    circuitBreaker.description !== ''
      ? `${circuitBreaker.title} / ${circuitBreaker.description}`
      : circuitBreaker.title;

  return (
    <ChartCardContainer
      title={circuitBreaker.equipment?.title}
      subTitle={subTitle}
      energyTotal={energyTotal}
    >
      <Component
        syncId={String(circuitBreaker.id)}
        hideTitle
        hideThresholdLines
        equipmentId={circuitBreaker.equipmentId as number}
        site={site}
        globalChartDetails={chartDetails}
        chartType={chartType}
      />
    </ChartCardContainer>
  );
};

export const EquipmentEnergyChartCard = withCard(EquipmentEnergyChart);

export default EquipmentEnergyChart;
