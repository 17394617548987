const USER_VISIT = 'User Visit';
const PAGE_VISIT = 'Page Visit';
const CONTROL_MODE_CHANGE = 'Control Mode Change';

const mixpanelEvents = {
    USER_VISIT,
    PAGE_VISIT,
    CONTROL_MODE_CHANGE,
};

export default mixpanelEvents;