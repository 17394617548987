import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import { Tab, Tabs } from '@energybox/react-ui-library/dist/components';
import { useDispatch } from 'react-redux';
import { showNewSensorModal } from '../../../../actions/sensors';
import {
  FirmwareGatewayModel,
  GatewayNetworkInterfaceHumanReadable,
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionStatus,
  Vendor,
  InspectionJumpToRef,
  OpenFirmwareUpdateModalFunc,
} from '@energybox/react-ui-library/dist/types';
import {
  ErrorIcon,
  Hub,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import InspectionTile, { InspectionHeader } from '../InspectionTile';
import InfoIconTooltip from '../InfoIconTooltip';
import { getSensorsTableColumns } from '../SensorsTile';
import {
  getId,
  getTitle,
  getDetailFields,
  getSummaryFields,
  getSensorsOrActuatorsSummaryFields,
  doesItemContainErrorOrWarning,
  transformDhcpStatusValue,
  isVersionRequirementFulfilled,
  checkFieldsErrorsAndWarnings,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { Routes } from '../../../../routes';
import { useCurrentUser } from '../../../../hooks/useAppDetails';
import styles from './HubTile.module.css';

type HubProps = {
  siteId: string;
  canUpdateFirmware: boolean;
  data: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  onFirmwareVersionLinkClick: OpenFirmwareUpdateModalFunc;
  jumpToRef: InspectionJumpToRef;
};

type SuperHubProps = {
  siteId: string;
  data: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  jumpToRef: InspectionJumpToRef;
  isSuper: true;
};

const HubTile = (props: HubProps | SuperHubProps) => {
  const { siteId, data, detailLevel, jumpToRef } = props;

  const currentUser = useCurrentUser();

  const dispatch = useDispatch();
  const displayNewSensorModal = (siteId: string = '', uuid: string = '') => {
    dispatch(showNewSensorModal({ resourceId: Number(siteId), uuid: uuid }));
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const hubDetailsPageLink = `${Routes.DEVICES}${Routes.GATEWAYS}/${getId(
    data
  )}`;

  if (!currentUser) return null;

  const hubFields = () => {
    if ('isSuper' in props) {
      return [
        {
          name: 'MCU Address (UUID)',
          key: 'UUID',
          link: hubDetailsPageLink,
        },
        {
          name: 'Firmware Version',
          key: 'firmware_version',
        },
        {
          name: 'RF Pan ID',
          key: 'rf_pan_id',
        },
        {
          name: 'Region',
          key: 'region',
        },
      ];
    } else {
      const { canUpdateFirmware, onFirmwareVersionLinkClick } = props;
      return [
        {
          name: 'IP Address',
          key: 'ip',
        },
        {
          name: 'DHCP Status',
          key: 'ip_setting',
          transformValue: transformDhcpStatusValue,
        },
        {
          name: 'Default Gateway',
          key: 'default_gateway',
        },
        {
          name: 'MCU Address (UUID)',
          key: 'UUID',
          link: hubDetailsPageLink,
        },
        {
          name: 'Subnet Mask',
          key: 'subnet_mask',
        },
        {
          name: 'Firmware Version',
          key: 'firmware_version',
          onClick: canUpdateFirmware
            ? () =>
                onFirmwareVersionLinkClick(
                  {
                    id:
                      ((data.id as InspectionDataField)?.field as number) || -1,
                    vendor:
                      ((data.vendor as InspectionDataField)?.field as Vendor) ||
                      '',
                  },
                  (data.vendor as InspectionDataField)?.field === Vendor.MONNIT
                    ? FirmwareGatewayModel.GHUB
                    : FirmwareGatewayModel.ENERGYBOX_HUB
                )
            : undefined,
        },
        {
          name: 'DNS 1',
          key: 'DNS_1',
        },
        {
          name: 'Connection Type',
          key: 'connection_type',
        },
        {
          name: 'DNS 2',
          key: 'DNS_2',
        },
        {
          name: 'Network Allowance',
          key: 'network_allowance',
          transformValue: value =>
            GatewayNetworkInterfaceHumanReadable[value] ||
            value ||
            global.NOT_AVAILABLE,
        },
        {
          name: '4G Carrier',
          key: 'network_operator',
        },
        {
          name: 'APN',
          key: 'apn',
        },
        {
          name: '4G RSSI',
          key: 'rssi',
          transformValue: value =>
            !isNaN(value) &&
            isDefined(value) &&
            (data.network_allowance as InspectionDataField)?.field !==
              'ETHERNET_ONLY'
              ? `${value} dB`
              : global.NOT_AVAILABLE,
        },
        {
          name: 'RF Pan ID',
          key: 'rf_pan_id',
        },
        {
          name: 'MQTT Broker',
          key: 'mqtt_broker',
          description: !isVersionRequirementFulfilled(
            (data.firmware_version as InspectionDataField)?.field as string,
            '1.2.628'
          ) ? (
            <InfoIconTooltip content="MQTT broker information only available from FW v1.2.628 onwards" />
          ) : (
            undefined
          ),
        },
      ];
    }
  };

  const isWhitelistSensorNull = (uuid: string = '') => {
    const findings = (data.whitelist as InspectionDataFieldsByKey[]).find(
      key => (key.uuid as InspectionDataFieldsByKey).field === uuid
    );
    return (findings && findings.sensor) === null;
  };

  const sensorsColumns = getSensorsTableColumns({
    data: data.sensors as InspectionDataFieldsByKey[],
    detailLevel,
    siteId,
    currentUser,
    linkToPairedSensorActions: `${hubDetailsPageLink}${Routes.PAIRED_SENSOR_ACTIONS}`,
    isWhitelistSensorNull,
    isWhiteListTab: selectedTab === 1,
    displayNewSensorModal,
  });
  const subtitle = getTitle(data);
  const tableData = [
    (data.sensors as InspectionDataFieldsByKey[]).filter(
      item =>
        item &&
        (detailLevel === InspectionDetailLevel.ALL ||
          doesItemContainErrorOrWarning(item))
    ) || [],
    (data.whitelist as InspectionDataFieldsByKey[])
      ?.filter(
        item =>
          item &&
          (detailLevel === InspectionDetailLevel.ALL ||
            doesItemContainErrorOrWarning(item))
      )
      .sort(item => {
        const finding = (item.uuid as InspectionDataFieldsByKey).field;
        return isWhitelistSensorNull(finding as string) ? -1 : 1;
      })
      .map(item =>
        item.sensor
          ? {
              ...(item.sensor as InspectionDataFieldsByKey[]),
            }
          : {
              UUID: item.uuid,
            }
      ) || [],
  ];

  const shouldHideTable =
    detailLevel === InspectionDetailLevel.ISSUES &&
    !tableData[0].length &&
    !tableData[1].length;
  const tabIcons = [0, 1].map(tab => {
    let hasError = false;
    let hasWarning = false;
    tableData[tab]?.some(item => {
      const errorsAndWarnings = checkFieldsErrorsAndWarnings(item);
      if (errorsAndWarnings.hasError) {
        hasError = true;
        return true;
      } else if (errorsAndWarnings.hasWarning) {
        hasWarning = true;
        return true;
      }
      return false;
    });
    if (hasError) {
      return <ErrorIcon width="16" height="16" className={styles.tabIcon} />;
    }
    if (hasWarning) {
      return <WarningIcon width="16" height="16" className={styles.tabIcon} />;
    }
    return null;
  });
  const hubSummaryFields = getSummaryFields(data, hubDetailsPageLink);
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(
    (data.sensors as InspectionDataFieldsByKey[]).concat(
      (data.whitelist as InspectionDataFieldsByKey[]) || []
    ),
    {
      statisticsData: {
        result: 'sensors',
        errors:
          ((data.errors_sensors as number) || 0) +
          ((data.errors_whitelist as number) || 0),
        warnings:
          ((data.warnings_sensors as number) || 0) +
          ((data.warnings_whitelist as number) || 0),
      },
    }
  );
  if (
    detailLevel === InspectionDetailLevel.ISSUES &&
    hubSummaryFields.status === InspectionStatus.GOOD &&
    (!sensorsSummaryFields.status ||
      sensorsSummaryFields.status === InspectionStatus.GOOD)
  ) {
    return null;
  }

  return (
    <InspectionTile
      title={
        'isSuper' in props
          ? InspectionComponentName.EB_SUPER_HUB
          : InspectionComponentName.HUB
      }
      titleIcon={<Hub variant="small" size="20" />}
      subtitle={
        !!subtitle && (
          <Link to={hubDetailsPageLink} target="_blank">
            {subtitle}
          </Link>
        )
      }
      summaryFields={hubSummaryFields}
      detailFields={getDetailFields(hubFields(), data, detailLevel)}
      detailTableHeader={
        shouldHideTable ? (
          undefined
        ) : (
          <>
            <InspectionHeader
              title={InspectionComponentName.SENSORS}
              summaryFields={sensorsSummaryFields}
            />
            <Cell width="13" className={styles.tabsContainer}>
              <Tabs>
                <Tab
                  active={selectedTab === 0}
                  onClick={() => setSelectedTab(0)}
                >
                  <div className={styles.tab}>
                    <div>Sensors</div>
                    {tabIcons[0]}
                  </div>
                </Tab>
                <Tab
                  active={selectedTab === 1}
                  onClick={() => setSelectedTab(1)}
                >
                  <div className={styles.tab}>
                    <div>Whitelist</div>
                    {tabIcons[1]}
                  </div>
                </Tab>
              </Tabs>
            </Cell>
          </>
        )
      }
      showDetailTableHeaderWhenCollapsed
      detailTable={
        shouldHideTable
          ? undefined
          : {
              dataIsLoading: false,
              columns: sensorsColumns,
              data: tableData[selectedTab],
            }
      }
      noTableDataMessage="No Sensors Configured"
      jumpToRef={jumpToRef}
    />
  );
};

export default HubTile;
