import * as R from 'ramda';
import history from './history';
import qs from 'qs';
import { SopComponentsByResourceId } from './reducers/sop';
import { SopComponent } from '@energybox/react-ui-library/dist/types';

/*
 * Takes an object and an selector array containing target object keys,
 * and creates a smaller set of object with selected keys
 *
 * e.g
 * data = {
 *  123: { id: 123, value: 'a' },
 *  456: { id: 456, value: 'b' },
 *  789: { id: 789, value: 'c' }
 * }
 * selectorArray = [123, 456]
 *
 * returns {
 *  123: { id: 123, value: 'a' },
 *  456: { id: 456, value: 'b' }
 * }
 */
export const getObjectWithSelectedKeys = (
  data: object = {},
  selectorArray: any[] = []
) => {
  return (
    selectorArray?.reduce((acc, key) => {
      acc[key] = data && data[key];
      return acc;
    }, {}) || {}
  );
};

export interface UserName {
  firstName: string;
  lastName: string;
}

export const fullName = ({ firstName, lastName }: UserName): string =>
  [firstName, lastName].filter(n => !!n).join(' ');

export const initials = ({ firstName, lastName }: UserName): string => {
  let result = '';
  result += firstName && firstName[0];
  result += lastName && lastName[0];

  return result.length > 0 ? result : '?';
};

// Immutable version of "delete object.key"
export const dropKey = (o: { [_: string]: any }, k: string) =>
  Object.keys(o || {}).reduce((result, key) => {
    if (key !== k) result[key] = o[key];
    return result;
  }, {});

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

// Splits given list using given boolean predicate
// Returns array where first element contains all positive outputs and second element all negative
export const split = (pred: (any) => boolean, data: any[]) => {
  const { true: positive = [], false: negative = [] } = R.groupBy(
    v => pred(v).toString(),
    data
  );

  return [positive, negative];
};

// Shallow replaces null values in any Object with undefined
export function replaceNullValues<T extends {}>(obj): T {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = value !== null ? value : undefined;
    return acc;
  }, {} as T);
}

// only handles common case, don't use for things like beach -> beaches
export const checkCommonPlural = (entity: string, count: number) => {
  if (count !== 1) return `${count} ${entity}s`;
  return `1 ${entity}`;
};

export const formatRequestArrayParam = array => {
  return array.join(',');
};

export const retainFilters = () => {
  const filterParams = history.location.search.substr(1);

  const filters = filterParams.split('?');

  const filtersSiteParams = qs.parse(filters[0], { comma: true });
  const siteFilters: number[] = Object.values(filtersSiteParams);

  const typeFiltersParams = qs.parse(filters[1], { comma: true });
  const typeFilters: any[] = Object.values(typeFiltersParams);

  let searchParse, pagination;

  if (filters.length === 3) {
    if (filters[2].length > 1) {
      searchParse = filters[2];
    } else {
      pagination = filters[2];
    }
  } else {
    searchParse = filters[2];
    pagination = filters[3];
  }

  let search;
  if (searchParse) {
    search = searchParse.replace('%20', ' ');
  }

  return {
    siteFilters,
    typeFilters,
    search,
    pagination,
  };
};

export const createAndUpdateSentinel = (escalations: any) => {
  // for(let i = 0 ; i< escalations.length; i++ ) {
  //   escalations[i].channelType.push('platform')
  // }
  let priorityLowLevel: any = 'NORMAL';
  let priorityHighestLevel: any = 'HIGHEST';
  escalations.forEach(escalation => {
    if (escalation.priorityLevel && escalation.priorityLevel == 'LOW') {
      escalation.priorityLevel = priorityLowLevel;
    } else if (
      escalation.priorityLevel &&
      escalation.priorityLevel == 'CRITICAL'
    ) {
      escalation.priorityLevel = priorityHighestLevel;
    }
    // for (let i = 0; i < escalation.channelType.length; i++) {
    //   escalation.channelType[i] = escalation.channelType[i].toUpperCase()
    // }
  });
  return escalations;
};

export const capitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const hasDaResults = testResults => {
  return (
    testResults && Object.values(testResults).some(value => Boolean(value))
  );
};

export const isInSitesPage = () => {
  const currentPath = window.location.pathname;
  const pattern1 = /\/sites\/\d+\//;
  const pattern2 = /\/(\d+)/;

  const hasPattern = pattern1.test(currentPath) || pattern2.test(currentPath);

  return hasPattern;
};

export const filterDataBySiteId = (data: SopComponentsByResourceId, id) => {
  const filteredData: SopComponent[] = [];
  for (const key in data) {
    const parsedKey = parseInt(key, 10); // Convert key to a number
    if (!isNaN(parsedKey) && parsedKey === id && Array.isArray(data[key])) {
      filteredData.push(...data[key]);
    }
  }
  return filteredData;
};
