import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import { getUniqueSiteIdsFromSelectedSiteGroups } from '../utils/getUniqueSiteIds';
import { getUrlStateParams } from './utils';
import history from '../history';
import { areArraysIdentical } from '../utils/areArraysIdentical';
import mixpanel from 'mixpanel-browser';

const useSiteGroupsFilter = () => {
  const siteGroupsById = useSelector(
    ({ siteGroups }: ApplicationState) => siteGroups.siteGroupsById ?? {}
  );

  const search = history?.location?.search;
  const selectedSitesBySiteGroups = useMemo(
    () => getUrlStateParams<number[]>(history, 'sites', []),
    [search]
  );
  let selectedSiteGroups = useMemo(
    () => getUrlStateParams<string[]>(history, 'siteGroups', []),
    [search]
  );

  const setSiteGroupsFilter = useCallback(
    props => {
      const siteGroupsMappedToFilterHaveNotChanged = areArraysIdentical(
        props,
        selectedSiteGroups
      );

      if (siteGroupsMappedToFilterHaveNotChanged) {
        return;
      }

      const uniqueSiteIds = getUniqueSiteIdsFromSelectedSiteGroups(
        siteGroupsById,
        props
      ) as number[];

      mixpanel?.track('Filter Applied', {
        type: 'Site Groups',
        selectedSiteGroups: props,
        siteIds: uniqueSiteIds,
      });

      setSiteGroupsAndSites(props, uniqueSiteIds);
    },
    [selectedSiteGroups, siteGroupsById, selectedSitesBySiteGroups]
  );

  const siteGroupWithoutSites =
    selectedSiteGroups?.length > 0 && !selectedSitesBySiteGroups?.length;

  return {
    siteGroupWithoutSites,
    setSiteGroupsFilter,
    selectedSiteGroups,
    selectedSitesBySiteGroups,
  };
};

export default useSiteGroupsFilter;

const setSiteGroupsAndSites = (siteGroups, uniqueSiteIds) => {
  const pathname = history?.location?.pathname;
  const updatedSearchParams = new URLSearchParams(history?.location?.search);

  updatedSearchParams.delete('sites');

  if (Array.isArray(siteGroups)) {
    updatedSearchParams.delete('siteGroups');
    siteGroups.forEach(value => {
      updatedSearchParams.append('siteGroups', value);
    });
  }

  if (Array.isArray(uniqueSiteIds)) {
    uniqueSiteIds.forEach(value => {
      updatedSearchParams.append('sites', value);
    });
  }

  history?.push({ pathname, search: updatedSearchParams.toString() });
};
