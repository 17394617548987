import React from 'react';

import equals from 'ramda/src/equals';
import { EnergySensor } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import {
  SubscribedEnergyProSensorsReadingBySensorId,
  ProposedReadng,
} from '../../reducers/subscribedEnergyPros';

const CircuitBreakerPhasorDiagram = ({
  energySensors,
}: {
  energySensors?: EnergySensor[];
}) => {
  const readingsByEnergySensorId = useSelector<
    ApplicationState,
    SubscribedEnergyProSensorsReadingBySensorId
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.subscribedEnergyProSensorsReadingBySensorId;
  }, equals);

  const proposedReadingByIndex = useSelector<
    ApplicationState,
    {
      [index: number]: ProposedReadng;
    }
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.proposedReadingByIndex;
  }, equals);

  return (
    <svg width="200" height="200">
      <defs>
        <marker
          id="arrowheadRed"
          markerWidth="5"
          markerHeight="3"
          refX="0"
          refY="1.5"
          orient="auto"
        >
          <polygon points="0 0, 5 1.5, 0 3" fill="var(--turquoise-base)" />
        </marker>
        <marker
          id="arrowheadBlue"
          markerWidth="5"
          markerHeight="3"
          refX="0"
          refY="1.5"
          orient="auto"
        >
          <polygon points="0 0, 5 1.5, 0 3" fill="var(--indigo-base)" />
        </marker>
      </defs>
      <circle
        cx="100"
        cy="100"
        r="60"
        stroke="var(--ambient-basePlus75)"
        strokeWidth="2"
        fill="transparent"
      />
      {energySensors &&
        energySensors.map(energySensor => {
          const { id: sensorId } = energySensor;
          const energyReading = readingsByEnergySensorId[energySensor.id];
          if (!energyReading) return null;
          const { index, phase } = energyReading;
          const proposedReadings = proposedReadingByIndex[index];
          if (!proposedReadings) return null;
          const { phaseAngle: currentAngle } = proposedReadings;
          const { phaseAngle: voltageAngle } = proposedReadingByIndex[
            199 + phase
          ];

          return (
            <React.Fragment key={`phasorDiagramVectorsForSensor${sensorId}`}>
              <line
                x1="100"
                y1="100"
                x2="135"
                y2="100"
                style={{ stroke: 'var(--turquoise-base)', strokeWidth: 2 }}
                transform={`rotate(-${voltageAngle} 100 100)`}
                markerEnd="url(#arrowheadRed)"
              />
              <g
                transform={`rotate(-${voltageAngle} 100 100) translate(50, 0)`}
              >
                <text
                  x="100"
                  y="100"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{
                    stroke: 'var(--turquoise-base)',
                    fontSize: '0.625rem',
                  }}
                  transform={`rotate(${voltageAngle} 100 100)`}
                >
                  {phase}
                </text>
              </g>
              <line
                x1="100"
                y1="100"
                x2="135"
                y2="100"
                style={{ stroke: 'var(--indigo-base)', strokeWidth: 2 }}
                transform={`rotate(-${currentAngle} 100 100)`}
                markerEnd="url(#arrowheadBlue)"
              />
              <g
                transform={`rotate(-${currentAngle} 100 100) translate(48, 0)`}
              >
                <text
                  x={100}
                  y={100}
                  textAnchor="middle"
                  style={{ stroke: 'var(--indigo-base)', fontSize: '0.625rem' }}
                  transform={`rotate(${currentAngle} 100 100)`}
                >
                  {phase}
                </text>
              </g>
            </React.Fragment>
          );
        })}

      <text
        x={50}
        y={195}
        style={{ stroke: 'var(--turquoise-base)', fontSize: '0.75rem' }}
      >
        Voltage
      </text>
      <text
        x={105}
        y={195}
        style={{ stroke: 'var(--indigo-base)', fontSize: '0.75rem' }}
      >
        Current
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={170}
        y={100}
        style={{ fontSize: '0.75rem' }}
      >
        0°
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={20}
        y={100}
        style={{ fontSize: '0.75rem' }}
      >
        180°
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={100}
        y={30}
        style={{ fontSize: '0.75rem' }}
      >
        90°
      </text>
      <text
        textAnchor="middle"
        stroke="var(--base10)"
        dominantBaseline="middle"
        x={100}
        y={175}
        style={{ fontSize: '0.75rem' }}
      >
        270°
      </text>
    </svg>
  );
};

export default CircuitBreakerPhasorDiagram;
