import {
  Button,
  InputField,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SensorActions,
  create,
  displayFormErrors,
  hideNewSensorModal,
  updateField,
} from '../../actions/sensors';
import ModalFormContent from '../../components/ModalFormContent';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/sensors';
import { CreateNewText, PropertyToLabel } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import SelectEquipment from '../Selects/SelectEquipment';
import SelectSite from '../Selects/SelectSite';
import SelectSpace from '../Selects/SelectSpace';
import SelectVendor from '../Selects/SelectVendor';

enum AttachedTo {
  EQUIPMENT = 'Equipment',
  SPACE = 'Space',
}

type OwnProps = {
  defaultSiteId?: number;
};

interface Props extends OwnProps {
  isVisible: boolean;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onCreate: (id: number) => void;
  formErrors: GenericErrors;
  fields: EditableFields;
  isLoading: boolean;
  disabled?: boolean;
  equipmentId?: number;
  apiError: ApiError;
  displayFormErrors: typeof displayFormErrors;
  formErrorsVisible: boolean;
  lockSiteId?: number;
  preselectedFields?: EditableFields;
}
interface State {
  siteId: number;
  attachedTo: AttachedTo;
  formattedUuid: string;
}
// TODO this thing is a mess, we need to clean it up.
// The complications start when you're limiting to an equipment vs limit to a site.

class NewSensorModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      siteId: props.lockSiteId || -1,
      attachedTo: AttachedTo.EQUIPMENT,
      formattedUuid: !!props.preselectedFields?.uuid
        ? props.preselectedFields.uuid
        : props.fields?.uuid || '',
    };
  }

  componentDidMount() {
    const { defaultSiteId, onChange } = this.props;

    if (defaultSiteId) {
      this.setState({ siteId: defaultSiteId });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      defaultSiteId,
      disabled,
      onChange,
      equipmentId,
      fields,
    } = this.props;
    if (
      this.props?.fields?.uuid === undefined &&
      this.state.formattedUuid &&
      this.props?.preselectedFields?.uuid === undefined
    ) {
      this.setState({ formattedUuid: '' });
    }
    if (defaultSiteId && defaultSiteId !== prevProps.defaultSiteId) {
      this.setState({ siteId: defaultSiteId });
    }
    if (disabled && fields && fields.resourceId === -1) {
      onChange('resourceId', equipmentId);
    }
  }

  onSensorCreate = () => {
    const {
      fields,
      lockSiteId,
      formErrors,
      displayFormErrors,
      onCreate,
    } = this.props;

    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate(lockSiteId || fields.resourceId);
    }
  };

  onSensorModalCancel = () => {
    if (!this.props.disabled) {
      this.setState({ siteId: -1 });
    }
    this.setState({ formattedUuid: '' });
    this.props.onClose();
  };

  onRadioButtonClick = (newValue: AttachedTo) => {
    if (!this.props.disabled) {
      this.props.onChange('resourceId', -1);
      this.setState({ attachedTo: newValue });
    }
  };

  onUUIDChange = (value: string) => {
    const { onChange } = this.props;
    const formattedUuid =
      value
        .replace(/[^0-9A-Fa-f]/g, '')
        ?.slice(0, 12)
        ?.toUpperCase()
        ?.match(/.{1,2}/g)
        ?.join(':') || '';
    this.setState({ formattedUuid });
    onChange('uuid', formattedUuid);
  };

  render() {
    if (!this.props.isVisible) return null;

    const {
      onClose,
      onChange,
      isLoading,
      formErrors,
      formErrorsVisible,
      fields,
      disabled,
      defaultSiteId,
      equipmentId,
      apiError,
      lockSiteId,
      preselectedFields,
    } = this.props;

    const { title, description, uuid, vendor, resourceId } = fields;
    const { siteId, attachedTo, formattedUuid } = this.state;

    const actions = (
      <>
        <Button variant="text" onClick={this.onSensorModalCancel}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={this.onSensorCreate}>
          Add
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
        <ModalTitle>{CreateNewText.SENSOR}</ModalTitle>
        <ModalContent>
          <ModalFormContent>
            <div>
              <Label required> Sensor name</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="title"
                autoComplete="title"
                value={title}
                onChange={e => onChange('title', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.title}
                customErrorText={formErrors.title && formErrors.title[0]}
              />
            </div>

            <div>
              <Label>{PropertyToLabel.description}</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="description"
                value={description}
                autoComplete="description"
                onChange={e => onChange('description', e.currentTarget.value)}
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.uuid}</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="uuid"
                value={formattedUuid}
                autoComplete="uuid"
                onChange={e => this.onUUIDChange(e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.uuid}
                customErrorText={formErrors.uuid && formErrors.uuid[0]}
                disabled={!!preselectedFields?.uuid}
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.vendor}</Label>
            </div>
            <div>
              <SelectVendor
                onSelect={value => onChange('vendor', value)}
                value={vendor}
                error={formErrorsVisible && !!formErrors.vendor}
                customErrorText={formErrors.vendor && formErrors.vendor[0]}
              />
            </div>
          </ModalFormContent>

          <ModalFormContent>
            <div>
              <Label required>{PropertyToLabel.siteId}</Label>
            </div>
            <div>
              <SelectSite
                disabled={
                  !!lockSiteId || disabled || !!preselectedFields?.resourceId
                }
                onSelect={value => {
                  this.setState({ siteId: value });
                  onChange('resourceId', -1);
                }}
                value={
                  !!preselectedFields?.resourceId
                    ? preselectedFields.resourceId
                    : disabled
                    ? defaultSiteId
                    : siteId
                }
              />
            </div>

            <div>
              <Label>Attached To</Label>
            </div>
            <RadioGroup>
              <RadioButton
                label={AttachedTo.EQUIPMENT}
                value={AttachedTo.EQUIPMENT}
                checked={attachedTo === AttachedTo.EQUIPMENT}
                onChange={() => this.onRadioButtonClick(AttachedTo.EQUIPMENT)}
              />
              {disabled ? null : (
                <RadioButton
                  label={AttachedTo.SPACE}
                  value={AttachedTo.SPACE}
                  checked={attachedTo === AttachedTo.SPACE}
                  onChange={() => this.onRadioButtonClick(AttachedTo.SPACE)}
                />
              )}
            </RadioGroup>

            {attachedTo === AttachedTo.EQUIPMENT ? (
              <>
                <div>
                  <Label>Equipment</Label>
                </div>
                <div>
                  <SelectEquipment
                    onSelect={value => onChange('resourceId', value)}
                    value={disabled ? equipmentId : resourceId}
                    siteId={
                      !!preselectedFields?.resourceId
                        ? preselectedFields.resourceId
                        : disabled && defaultSiteId
                        ? defaultSiteId
                        : siteId
                    }
                    disabled={disabled}
                    error={formErrorsVisible && !!formErrors.resourceId}
                    customErrorText={
                      formErrors.resourceId && formErrors.resourceId[0]
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <Label required>Space</Label>
                </div>
                <div>
                  <SelectSpace
                    onSelect={value => onChange('resourceId', value)}
                    value={resourceId}
                    siteId={
                      !!preselectedFields?.resourceId
                        ? preselectedFields.resourceId
                        : siteId
                    }
                    error={formErrorsVisible && !!formErrors.resourceId}
                    customErrorText={
                      formErrors.resourceId && formErrors.resourceId[0]
                    }
                  />
                </div>
              </>
            )}
          </ModalFormContent>

          <ModalFormContent>
            <Label>* Mandatory fields</Label>
          </ModalFormContent>
          {renderAPIerror(apiError, SensorActions.CREATE_SENSOR_ERROR)}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ sensors }: ApplicationState) => ({
  isVisible: sensors.showNewSensorModal,
  ...sensors.editById['new'],
});

const mapDispatchToProps = {
  onClose: () => hideNewSensorModal(),
  onChange: (field: string, value: string) => updateField('new', field, value),
  onCreate: (lockSiteId?: number) => create(lockSiteId),
  displayFormErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSensorModal);
