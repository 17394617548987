import { Actions as SchedulerActions } from '../actions/schedulers';
import {
  updateField,
  Actions as TemperatureControlActions,
} from '../actions/temperature_controls';
import {
  showNewControlModal,
  hideUpdateControlModeModal,
} from '../actions/controls';
import { Actions as HvacControlActions } from '../actions/hvacControls';
import { Actions as SopActions } from '../actions/sops';
import {
  getNetworkGroupsBySopId,
  Actions as NetworkGroupActions,
} from '../actions/network_groups';
import { Actions as EquipmentActions } from '../actions/equipment';
import { produceEdgeConfig } from '../actions/edge_devices';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';

const controlsMiddleware = store => next => action => {
  switch (action.type) {
    case SchedulerActions.CREATE_NEW_SCHEDULER_IN_STORE: {
      store.dispatch(showNewControlModal());
      break;
    }

    case SchedulerActions.CREATE_SCHEDULER_TEMPERATURE_CONTROLS_ERROR: {
      store.dispatch(updateField('new', 'schedulerId', null));
      break;
    }

    case HvacControlActions.PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS:
    case SchedulerActions.PATCH_SCHEDULER_CONTROL_MODE_SUCCESS:
    case TemperatureControlActions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_SUCCESS: {
      store.dispatch(hideUpdateControlModeModal());
      break;
    }

    case EquipmentActions.PATCH_EQUIPMENT_SUCCESS:
    case HvacControlActions.CREATE_HVAC_CONTROL_SUCCESS:
    case HvacControlActions.PATCH_HVAC_CONTROL_SUCCESS: {
      const shouldProduceEdgeConfig =
        action.options?.produceEdgeConfig === true;
      const newNetworkGroupId =
        action.options?.newNetworkGroupId ||
        action.data?.thermostat?.networkGroupId;
      const oldNetworkGroupId = action.options?.oldNetworkGroupId;
      if (shouldProduceEdgeConfig && isDefined(newNetworkGroupId)) {
        //CREATE and PATCH
        store.dispatch(produceEdgeConfig(newNetworkGroupId));
        if (
          isDefined(oldNetworkGroupId) &&
          oldNetworkGroupId !== newNetworkGroupId
        ) {
          //PATCH only
          store.dispatch(produceEdgeConfig(oldNetworkGroupId));
        }
      }
      break;
    }
    case HvacControlActions.DELETE_HVAC_CONTROL_SUCCESS: {
      const shouldProduceEdgeConfig =
        action.options?.produceEdgeConfig === true;
      const networkGroupId =
        action.options?.networkGroupId ||
        action.data?.thermostat?.networkGroupId;
      if (shouldProduceEdgeConfig && isDefined(networkGroupId)) {
        store.dispatch(produceEdgeConfig(networkGroupId));
      }
      break;
    }

    //in this specific case, SopActions.DELETE_SOP_SUCCESS should only apply for deleting HVAC SOPs
    case SopActions.DELETE_SOP_SUCCESS:
    case SopActions.UPDATE_HVAC_SOP_SUCCESS: {
      if (Array.isArray(action.options?.produceEdgeConfigByNetworkGroupIds)) {
        action.options.produceEdgeConfigByNetworkGroupIds.forEach(
          networkGroupId => {
            store.dispatch(produceEdgeConfig(networkGroupId));
          }
        );
      }
      break;
    }

    //when a user creates an hvac-sop, we ultimately want to
    //produceEdgeConfigs of all networkGroups associated with this new hvac-sop.
    //to do this we first have to getNetworkGroupsBySopId(),
    //then from that result need to produceEdgeConfig() each networkGroup.
    //In this controls middleware then,
    //SopActions.CREATE_HVAC_SOP_SUCCESS and NetworkGroupActions.GET_NETWORK_GROUPS_BY_SOP_ID_SUCCESS
    //are closely intertwined
    case SopActions.CREATE_HVAC_SOP_SUCCESS: {
      const { currentOrganizationId } = store.getState().app;
      const newlyCreatedHvacSopId = action.data?.id;

      if (
        isDefined(newlyCreatedHvacSopId) &&
        isDefined(currentOrganizationId)
      ) {
        store.dispatch(
          getNetworkGroupsBySopId(
            newlyCreatedHvacSopId,
            currentOrganizationId,
            { produceEdgeConfig: true }
          )
        );
      }
      break;
    }

    case NetworkGroupActions.GET_NETWORK_GROUPS_BY_SOP_ID_SUCCESS: {
      if (action.options?.produceEdgeConfig === true) {
        const networkGroupIds: number[] | undefined = action.data?.map(
          (d: NetworkGroup) => d.id
        );

        if (isDefined(networkGroupIds) && networkGroupIds.length > 0) {
          networkGroupIds.forEach((networkGroupId: number) => {
            store.dispatch(produceEdgeConfig(networkGroupId));
          });
        }
      }
      break;
    }

    default:
      break;
  }

  next(action);
};

export default controlsMiddleware;
